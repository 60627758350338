import Constants from 'api-constants'
import { HashMap, IHeaders } from '../..'

/**
 * A custom error class for Volt applications
 */
export default class VoltError extends Error {
    static UNKNOWN_NUM_CODE = '001' // 001 -> Default Generic failure number code
    /**
     *
     * @param {VoltError.codes} error Error Object given by throwError()
     * @param {VoltError.options} Object
     * @param {String} context OZZ -> Default UNKNOWN_ERROR letter code
     * @param {String|number} errorVariant Digit to help to differenciate error between same error raised by different error code from the backend
     * @param {String} backend Backend for being able to display the backend at the origin of the error (useful for Project with multiple backend)
     * @param {String} extraLog Extra error log added by certain platforms (usually backends)
     * @param {Number} [httpErrorStatus] Refer to (https://developer.mozilla.org/en-US/docs/Web/HTTP/Status)
     * @param {VoltError} inheritedError The error further in the call stack which preceeds and is encapsulated by this instance of VoltError
     */

    inheritedError?: VoltError
    code?: string
    numCode: string
    description?: string
    playerAutoRetry: boolean
    context?: string
    extraLog: string
    backend: string
    errorCode: string
    errorVariant?: string | number
    httpUrl: string | undefined
    httpErrorStatus?: number | string
    httpResponseHeaders?: number | string | IHeaders
    httpResponseBody?: number | string | HashMap<string, string>
    backendDisplayMessage?: string
    backendErrorCode?: string | number | undefined
    backendErrorMessage?: string
    disableFirebaseReporting?: boolean
    customNumCode?: string
    reAuthenticate?: boolean
    acr_value?: string

    constructor(
        error: {
            code?: string
            defaultContext?: string
            description?: string
            numCode?: string
            playerAutoRetry?: boolean
            disableFirebaseReporting?: boolean
            reAuthenticate?: boolean
            acr_value?: string
        } = VoltError.codes.UNKNOWN_ERROR,
        {
            errorVariant,
            context,
            extraLog,
            backend,
            inheritedError,
            httpUrl,
            httpErrorStatus,
            backendDisplayMessage,
            backendErrorCode,
            backendErrorMessage,
            customNumCode,
            reAuthenticate,
            acr_value,
        }: {
            context?: string
            extraLog?: string
            errorVariant?: string | number
            backend?: string
            inheritedError?: string | VoltError
            httpUrl?: string | undefined
            httpErrorStatus?: number
            backendDisplayMessage?: string
            backendErrorCode?: string | number | undefined
            backendErrorMessage?: string
            customNumCode?: string
            reAuthenticate?: boolean
            acr_value?: string
        } = {}
    ) {
        super(error.description)
        if (inheritedError) {
            this.inheritedError =
                typeof inheritedError === 'string' ||
                inheritedError instanceof String ||
                Number.isFinite(inheritedError)
                    ? new VoltError({
                          ...VoltError.codes.UNKNOWN_ERROR,
                          description: inheritedError as string,
                      })
                    : inheritedError
        }
        this.reAuthenticate = error.reAuthenticate
        this.acr_value = error.acr_value
        this.code = error.code
        this.description = error.description
        this.numCode = error.numCode || VoltError.UNKNOWN_NUM_CODE
        this.playerAutoRetry = error.playerAutoRetry === undefined ? true : error.playerAutoRetry
        this.context = context ? context : error.defaultContext
        this.extraLog = extraLog ? extraLog : ''
        this.backend = backend || (this.inheritedError && this.inheritedError.backend) || ''
        this.errorVariant = errorVariant
        this.httpUrl = httpUrl || (this.inheritedError && this.inheritedError.httpUrl) || undefined
        this.httpErrorStatus =
            httpErrorStatus ||
            (this.inheritedError && this.inheritedError.httpErrorStatus) ||
            undefined
        this.httpResponseHeaders =
            httpErrorStatus ||
            (this.inheritedError && this.inheritedError.httpResponseHeaders) ||
            undefined
        this.httpResponseBody =
            httpErrorStatus ||
            (this.inheritedError && this.inheritedError.httpResponseBody) ||
            undefined
        this.backendErrorCode =
            backendErrorCode ||
            (this.inheritedError && this.inheritedError.backendErrorCode) ||
            undefined
        this.backendErrorMessage =
            backendErrorMessage ||
            (this.inheritedError && this.inheritedError.backendErrorMessage) ||
            undefined
        this.backendDisplayMessage = backendDisplayMessage
        this.customNumCode = customNumCode
        this.disableFirebaseReporting = error.disableFirebaseReporting
        this.errorCode =
            this.code === VoltError.codes.UNKNOWN_API_ERROR.code && this.backendErrorCode
                ? this._buildErrorCode(
                      Constants.errorsContext.EXTERNAL,
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      /\d/.test(this.backendErrorCode) ? this.backendErrorCode : this.numCode,
                      this.backend,
                      this.errorVariant,
                      this.customNumCode,
                      this.acr_value,
                      this.reAuthenticate
                  )
                : this._buildErrorCode(
                      this.context,
                      this.numCode,
                      this.backend,
                      this.errorVariant,
                      this.customNumCode,
                      this.acr_value,
                      this.reAuthenticate
                  )
    }

    /** Setter Url */
    setHttpUrl(url: string) {
        this.httpUrl = url
    }
    setReAuthenticate(reAuthenticate: boolean) {
        this.reAuthenticate = reAuthenticate
    }
    setAcrValue(acr_value: string) {
        this.acr_value = acr_value
    }

    /**
     * Setter to ease the code because otherwise we will have to add httpErrorStatus props at each instanciation of the error
     * @param {Number} httpErrorStatus Refer to (https://developer.mozilla.org/en-US/docs/Web/HTTP/Status)
     */
    setHttpErrorStatus(httpErrorStatus: number) {
        this.httpErrorStatus = httpErrorStatus
    }

    /** Setter Response Headers */
    setHttpResponseHeaders(httpResponseHeaders: IHeaders) {
        this.httpResponseHeaders = httpResponseHeaders
    }

    /** Setter Response Headers */
    setHttpResponseBody(httpResponseBody: HashMap<string, string>) {
        this.httpResponseBody = httpResponseBody
    }

    /**
     * Build Error code as follow CTX-CODE-BAC. Example: PLB-001-GOO for CTX (Context: PLAYBACK) and BAC (Backend Google)
     * @param {String} context Context of the error
     * @param {String} numCode Code number
     * @param {String} backend Backend at the origin of the error
     * @param {String} discriminator Digit to help to differenciate error between same error raised by different error code from the backend
     * @returns Error code formatted. Example PLB-001-GOO
     */
    _buildErrorCode(
        context = '',
        numCode: string | number | undefined,
        backend: string,
        discriminator: string | number | undefined,
        customNumCode: string | undefined,
        acr_value?: string,
        reAuthenticate?: boolean
    ) {
        const result = `${context ? context + '-' : ''}${
            customNumCode
                ? customNumCode
                : typeof numCode === 'number'
                ? numCode
                : numCode?.substring(0, 10).toUpperCase()
        }${
            discriminator
                ? typeof discriminator === 'number'
                    ? '-' + discriminator
                    : discriminator?.substring(0, 1).toUpperCase()
                : ''
        }${backend ? '-' + backend.substring(0, 3).toUpperCase() : ''} ${
            acr_value ? acr_value : ''
        } ${reAuthenticate ? reAuthenticate : ''}`

        return result
    }

    getAllErrorCodes(): string[] {
        const code = this.code || ''
        return [
            code,
            ...(this.inheritedError && this.inheritedError.getAllErrorCodes
                ? this.inheritedError.getAllErrorCodes()
                : []),
        ]
    }

    hasErrorCodeInStack(error: { code: string }) {
        return this.getAllErrorCodes().includes(error.code)
    }

    static videoPlayerComponentToId(component: string) {
        switch (component) {
            case 'TIF':
                return 'TIF'
            case 'Exoplayer':
                return 'EXO'
            case 'ExoplayerInternal':
                return 'EXI'
            case 'NagraBroadpeak':
            case 'ExoplayerBroadpeak':
                return 'BPK'
            case 'Nagra':
                return 'NAG'
            case 'NagraInternal':
                return 'NAI'
            case 'WebPlayer':
                return 'WEB'
            case 'WebPlayerInternal':
                return 'WEI'
            default:
                return 'UNK'
        }
    }
    /**
     * Return a VoltError from a React Native Video error String
     *
     * @param {String} error.code error code returned by react native Video
     * @param {String} error.exception error description returned by react native Video
     * @param {String} player.playerName name of the player
     */
    static videoPlayerErrorToVoltError(
        error?: {
            code?: string
            exception?: string
            iOSCode?: string | number
            rawError: string
            component: string
            acr_value?: string
            reAuthenticate?: boolean
        },
        player?: { playerName?: string }
    ) {
        if (!error) return new VoltError(VoltError.codes.PLAYBACK_ERROR)
        // on react native-video v6 the errorString is mandatory
        if (!error.code) return new VoltError(VoltError.codes.PLAYBACK_ERROR)

        // Passing the error object allow flexible implementation
        // For previous / next release support of react-native-video
        let voltError
        switch (error.code) {
            /////////////////////////////////////////
            // Generic Errors
            /////////////////////////////////////////
            case 'Unknown':
                voltError = VoltError.codes.PLAYBACK_ERROR
                break
            case 'Decoder':
                voltError = VoltError.codes.PLAYER_DECODER_ERROR
                break
            case 'Format':
                voltError = VoltError.codes.PLAYER_INVALID_FORMAT
                break
            case 'FileNotFound':
                voltError = VoltError.codes.PLAYER_SESSION_ERROR_STREAM_NOT_FOUND
                break
            case 'InvalidState':
                voltError = VoltError.codes.PLAYER_INVALID_STATE
                break
            case 'Canceled':
                voltError = VoltError.codes.PLAYER_CANCELED
                break
            case 'Network':
                voltError = VoltError.codes.PLAYER_NETWORK_ERROR
                break
            case 'InvalidParamUrl':
            case 'InvalidParam':
                voltError = VoltError.codes.PLAYER_SESSION_ERROR_DUE_TO_INVALID_PARAMETER
                break
            case 'InvalidStream':
                voltError = VoltError.codes.PLAYER_SESSION_ERROR_INVALID_STREAM
                break
            case 'Timeout':
                voltError = VoltError.codes.PLAYER_TIMEOUT
                break
            case 'MaxConcurentStream':
                voltError = VoltError.codes.PLAYER_SESSION_MAX_STREAM_REACHED
                break
            case 'DeviceNotInHousehold':
                voltError = VoltError.codes.PLAYER_DEVICE_NOT_IN_HOUSEHOLD
                break
            case 'DeviceNotProvisionned':
                voltError = VoltError.codes.PLAYER_DEVICE_NOT_PROVISIONNED
                break
            case 'Restricted':
                voltError = VoltError.codes.PLAYER_PLAYBACK_RESTRICTED
                break
            case 'ContentNotFound':
                voltError = VoltError.codes.PLAYER_ENTITLEMENT_NO_SUCH_ASSET
                break
            case 'AudioTrackInit':
                voltError = VoltError.codes.PLAYER_ERROR_DRM_AUDIO_TRACK_INIT
                break
            case 'SlidingBehindLiveWindow':
                voltError = VoltError.codes.PLAYER_ERROR_SLIDING_BEHIND_LIVE_WINDOW
                break
            case 'DecoderExceedsDeviceCapability':
                voltError = VoltError.codes.PLAYER_ERROR_DECODER_EXCEEDS_DEVICE_CAPABILITIES
                break
            case 'NetworkClearTextNotPermitted':
                voltError = VoltError.codes.PLAYER_ERROR_NETWORK_CLEARTEXT_NOT_PERMITTED
                break
            case 'NetworkInvalidContentType':
                voltError = VoltError.codes.PLAYER_ERROR_NETWORK_INVALID_CONTENT_TYPE
                break
            case 'ServerTimeout':
                voltError = VoltError.codes.PLAYER_ERROR_SERVER_TIMEOUT
                break
            case 'ErrorIo':
                voltError = VoltError.codes.PLAYER_ERROR_DATA_IO
                break
            case 'AudioDecoder':
                voltError = VoltError.codes.PLAYER_AUDIO_DECODER_ERROR
                break
            /////////////////////////////////////////
            // DRM Errors
            /////////////////////////////////////////
            case 'DRM':
                voltError = VoltError.codes.PLAYER_ENTITLEMENT_NO_DRM_FOUND
                break
            case 'DrmProvisionFailured':
                voltError = VoltError.codes.PLAYER_DRM_PROVISION_FAILURE
                break
            case 'DrmDecryptionFailure':
                voltError = VoltError.codes.PLAYER_DRM_DECRYPTION_FAILURE
                break
            case 'DrmNotSupported':
                voltError = VoltError.codes.PLAYER_DRM_NOT_SUPPORTED
                break
            case 'DrmDataFailure':
                voltError = VoltError.codes.PLAYER_DRM_DATA_INIT_FAILURE
                break
            case 'DrmKeyInvalid':
                voltError = VoltError.codes.PLAYER_DRM_KEY_INVALID
                break
            case 'DrmKeyExpired':
                voltError = VoltError.codes.PLAYER_DRM_KEY_EXPIRED
                break
            case 'DrmFetchFailure':
                voltError = VoltError.codes.PLAYER_DRM_FETCH_FAILURE
                break
            case 'DrmSessionSetupFailure':
                voltError = VoltError.codes.PLAYER_DRM_SESSION_SETUP_FAILURE
                break
            case 'PermanentDrm':
                voltError = VoltError.codes.PLAYER_PERMANENT_DRM_ERROR
                break
            case 'DrmDeviceRevoked':
                voltError = VoltError.codes.PLAYER_ERROR_DRM_REVOKED
                break
            case 'DrmIncompatibleContent':
                voltError = VoltError.codes.PLAYER_ERROR_DRM_INCOMPATIBLE_CONTENT
                break
            case 'DrmIncompatiblePolicy':
                voltError = VoltError.codes.PLAYER_ERROR_DRM_INCOMPATIBLE_POLICY
                break

            /////////////////////////////////////////
            // Session Errors
            /////////////////////////////////////////
            case 'SessionCreationFailure':
                voltError = VoltError.codes.PLAYER_NATIVE_SESSION_CREATION_FAILURE
                break
            case 'SessionRenewalFailure':
                voltError = VoltError.codes.PLAYER_NATIVE_SESSION_RENEWAL_FAILURE
                break
            case 'SessionTeardownFailure':
                voltError = VoltError.codes.PLAYER_NATIVE_SESSION_TEARDOWN_FAILURE
                break
            case 'SessionErrorInvalidRequest':
                voltError = VoltError.codes.PLAYER_SESSION_ERROR_DUE_TO_INVALID_PARAMETER
                break
            case 'SessionErrorDeviceUuid':
                voltError = VoltError.codes.PLAYER_DEVICE_INVALID
                break
            case 'SessionErrorInvalidToken':
                voltError = VoltError.codes.PLAYER_ERROR_DUE_TO_INVALID_TOKEN
                break
            case 'SessionErrorMaxSession':
                voltError = VoltError.codes.PLAYER_SESSION_MAX_STREAM_REACHED
                break
            case 'SessionErrorSessionManagementDisabled':
                voltError = VoltError.codes.PLAYER_SESSION_ERROR_SESSION_DISABLED
                break
            case 'SessionErrorInvalidDevice':
                voltError = VoltError.codes.PLAYER_DEVICE_NOT_SUPPORTED
                break
            case 'SessionErrorKeyError':
                voltError = VoltError.codes.PLAYER_DRM_KEY_INVALID
                break
            case 'SessionErrorUnknownAccount':
                voltError = VoltError.codes.PLAYER_SESSION_ERROR_INVALID_ACCOUNT
                break
            case 'SessionErrorUnknownDevice':
                voltError = VoltError.codes.PLAYER_DEVICE_NOT_SUPPORTED
                break
            case 'SessionErrorInvalidExpirationTime':
                voltError = VoltError.codes.PLAYER_SESSION_ERROR_EXPIRATION_TIME
                break
            case 'SessionErrorInvalidConfig':
                voltError = VoltError.codes.PLAYER_SESSION_ERROR_INVALID_CONFIG
                break
            case 'Unauthorized':
                voltError = VoltError.codes.PLAYER_CONTENT_UNAUTHORIZED
                break
            case 'UnauthorizedDevice':
                voltError = VoltError.codes.PLAYER_DEVICE_UNAUTHORIZED
                break
            case 'Unavailable':
                voltError = VoltError.codes.PLAYER_CONTENT_UNAVAILABLE
                break
            case 'deviceInUseByAnotherApplication':
                voltError = VoltError.codes.PLAYER_DEVICE_USED_BY_ANOTHER_APP
                break
            case 'VideoNotSupported':
                voltError = VoltError.codes.PLAYER_VIDEO_NOT_SUPPORTED
                break
            case 'VideoExceeded':
                voltError = VoltError.codes.PLAYER_VIDEO_CAPACITY_EXCEEDED
                break
            case 'DisplayError':
                voltError = VoltError.codes.PLAYER_DISPLAY_ERROR
                break
            case 'SessionUnknownError':
                voltError = VoltError.codes.PLAYER_SESSION_ERROR
                break
            case 'CdnUnknownError':
                voltError = VoltError.codes.PLAYER_CDN_UNKNOWN_ERROR
                break
            case 'CdnUrlNotFound':
                voltError = VoltError.codes.PLAYER_CDN_URL_NOT_FOUND
                break
            case 'CdnResponseUnreadable':
                voltError = VoltError.codes.PLAYER_CDN_RESPONSE_UNREADABLE
                break
            case 'CdnServiceNotAvailable':
                voltError = VoltError.codes.PLAYER_CDN_SERVICE_NOT_AVAILABLE
                break
            case 'CdnChannelNotAvailable':
                voltError = VoltError.codes.PLAYER_CDN_CHANNEL_NOT_AVAILABLE
                break
            case 'CdnNanoCdnNotAllowedToConnectOnWifi':
                voltError = VoltError.codes.PLAYER_NANO_CDN_NOT_ALLOWED_TO_CONNECT_ON_WIFI
                break
            case 'CdnApiError':
                voltError = VoltError.codes.PLAYER_CDN_API_ERROR
                break
            case 'CdnOperationAlreadyDone':
                voltError = VoltError.codes.PLAYER_CDN_OPERATION_ALREADY_DONE
                break
            /////////////////////////////////////////
            // WEB VIEW
            /////////////////////////////////////////
            case 'Html5':
                voltError = VoltError.codes.PLAYER_HTML5_ERROR
                break
            case 'VideoNotPlayableInEmbdedPlayer':
                voltError = VoltError.codes.PLAYER_VIDEO_NOT_PLAYABLE_IN_EMBDED_PLAYER
                break
            case 'WebViewNotAvailable':
                voltError = VoltError.codes.PLAYER_WEBVIEW_NOT_AVAILABLE
                break
            default:
                voltError = VoltError.codes.PLAYBACK_ERROR
                break
        }

        let backendErrorCode
        if (error?.exception) {
            backendErrorCode = error?.exception
        } else if (error?.iOSCode && typeof error?.iOSCode === 'number') {
            backendErrorCode = error?.iOSCode.toString()
        } else if (error?.iOSCode && typeof error?.iOSCode === 'string') {
            backendErrorCode = error?.iOSCode
        }

        let numcode
        if (error.rawError && error.component) {
            // player integrates raw error management
            numcode =
                error.rawError.replace('-', '') +
                '-' +
                VoltError.videoPlayerComponentToId(error.component)
        }

        return new VoltError(voltError, {
            backend: player?.playerName,
            backendErrorCode,
            customNumCode: numcode,
            acr_value: error.acr_value,
            reAuthenticate: error.reAuthenticate,
        })
    }

    // ---------------------------
    // STATIC LIST OF ALL ERRORS //
    // ---------------------------

    /** @type {VoltErrorCodes} */
    static codes = {
        // ----------------------------------
        /**
         * BACKEND ERRORS CATEGORIES
         * COMMONLY KNOWN AS VOLT ERRORS
         * Type: is Backend
         */
        // ----------------------------------

        /**
         **
         * AUTHENTICATION ERRORS - 100
         **
         */
        /**
         * @description AUTH_ERROR
         */
        AUTH_ERROR: {
            code: 'AUTH_ERROR',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Generic Authentication Error',
            numCode: '100',
        },
        /**
         * @description AUTH_OAUTH_EXPIRED_TOKEN
         * To be used when the backend respond that the token has expired despite it is valid.
         * Used for OAUTH device flow timeout
         */
        AUTH_OAUTH_EXPIRED_TOKEN: {
            code: 'AUTH_OAUTH_EXPIRED_TOKEN',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Valid Token Expired',
            numCode: '101',
        },
        /**
         * @description AUTH_OAUTH_SLOW_DOWN_POLLING
         * Not really an error but exception managed by Oauth standard.
         * When raised, we need to reduce polling from the server for Oauth device flow login (QR Code)
         */
        AUTH_OAUTH_SLOW_DOWN_POLLING: {
            code: 'AUTH_OAUTH_SLOW_DOWN_POLLING',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'OAuth Standard Exception',
            numCode: '102',
        },
        /**
         * @description AUTH_OAUTH_WAITING_LOGIN_WITH_SECONDARY_DEVICE
         * Not really an error.
         * Waiting login with secondary device (Mobile, Phone, Tablet)
         */
        AUTH_OAUTH_WAITING_LOGIN_WITH_SECONDARY_DEVICE: {
            code: 'AUTH_OAUTH_WAITING_LOGIN_WITH_SECONDARY_DEVICE',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Secondary Device Login',
            numCode: '103',
        },
        /**
         * @description AUTH_2FA_REQUIRED_BUT_NO_OTP_PROVIDED
         * 'Two Factor Authentication is required
         * No OTP is provided
         */
        AUTH_2FA_REQUIRED_BUT_NO_OTP_PROVIDED: {
            code: 'AUTH_2FA_REQUIRED_BUT_NO_OTP_PROVIDED',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Two Factor Authentication',
            numCode: '104',
        },
        /**
         * @description AUTH_INVALID_CONFIG
         * Error due to bad configuration of the authentication embedded in our application
         * Client id, client secret, scope, grant type
         */
        AUTH_INVALID_CONFIG: {
            code: 'AUTH_INVALID_CONFIG',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Invalid Authentication Configuration',
            numCode: '105',
        },
        /**
         * @description AUTH_PASSWORD_COMPLEXITY_NOT_MET
         * Sign In or Sign Up refused.
         * Password does not meet expectations from the backend
         */
        AUTH_PASSWORD_COMPLEXITY_NOT_MET: {
            code: 'AUTH_PASSWORD_COMPLEXITY_NOT_MET',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Wrong Password Complexity',
            numCode: '106',
        },
        /**
         * @description AUTH_USERNAME_MUST_BE_PHONE_NUMBER
         * During authentication on boarding the user must provide a phone number for username
         */
        AUTH_USERNAME_MUST_BE_PHONE_NUMBER: {
            code: 'AUTH_USERNAME_MUST_BE_PHONE_NUMBER',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Username Without Phone Number',
            numCode: '107',
        },
        /**
         * @description AUTH_UNAUTHORIZED_USER
         * Authentication not authorized for some user or device ID
         * Used mainly to invite users to call the customer service
         */
        AUTH_UNAUTHORIZED_USER: {
            code: 'AUTH_UNAUTHORIZED_USER',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Unauthorized User',
            numCode: '108',
        },
        /**
         * @description INVALID_AUTH_TOKEN
         */
        INVALID_AUTH_TOKEN: {
            code: 'INVALID_AUTH_TOKEN',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Invalid Authentication Token',
            numCode: '109',
        },
        /**
         * @description RENEW_TOKEN_FAILED
         */
        RENEW_TOKEN_FAILED: {
            code: 'RENEW_TOKEN_FAILED',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Renew Token Failed',
            numCode: '110',
        },
        /**
         * @description TOKEN_RETRIEVAL_ERROR
         */
        TOKEN_RETRIEVAL_ERROR: {
            code: 'TOKEN_RETRIEVAL_ERROR',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Token Retrieval Error',
            numCode: '111',
        },
        /**
         * @description OTP_COMPLEXITY_NOT_MET
         */
        OTP_COMPLEXITY_NOT_MET: {
            code: 'OTP_COMPLEXITY_NOT_MET',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Otp Complexity not met',
            numCode: '112',
        },
        /**
         * @description
         */
        PASSWORD_MODIFICATION_FAILED: {
            code: 'PASSWORD_MODIFICATION_FAILED',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Password Modification Failed',
            numCode: '113',
        },
        /**
         * @description One Time Password invalid
         */
        OTP_INVALID: {
            code: 'OTP_INVALID',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'OTP Invalid (One Time Password)',
            numCode: '114',
        },
        /**
         * @description Access Token 'jti' check fails
         */
        INVALID_JTI_TOKEN: {
            code: 'INVALID_JTI_TOKEN',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Invalid JWT JTI Check from the backend',
            numCode: '115',
        },
        /**
         * @description AUTH_UNKNOWN_DEVICE
         * Login Unknown Device ID
         */
        AUTH_UNKNOWN_DEVICE: {
            code: 'AUTH_UNKNOWN_DEVICE',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Authentication: Unknown Device',
            numCode: '116',
        },
        /**
         * @description Login Disabled Device
         */
        AUTH_DISABLED_DEVICE: {
            code: 'AUTH_DISABLED_DEVICE',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Authentication: Disabled Device',
            numCode: '117',
        },
        /**
         * @description Login Disabled Device
         */
        AUTH_INACTIVE_DEVICE: {
            code: 'AUTH_INACTIVE_DEVICE',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Authentication: Inactive Device',
            numCode: '118',
        },
        /**
         * @description Invalid Device Data
         */
        AUTH_ERROR_METADATAS_DEVICE: {
            code: 'AUTH_ERROR_METADATAS_DEVICE',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Authentication: Invalid Device Data',
            numCode: '119',
        },
        /**
         * @description Retrieving payment gateway Token error
         */
        PAYMENT_TOKEN_ERROR: {
            code: 'PAYMENT_TOKEN_ERROR',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Authentication: Retrieving payment gateway token error',
            numCode: '120',
        },
        /**
         * @description Invalid grant
         */
        AUTH_INVALID_GRANT: {
            code: 'AUTH_INVALID_GRANT',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Invalid grant',
            numCode: '121',
        },
        /**
         * @description Cannot login because device id is not registered by the backend
         */
        AUTH_DEVICE_NOT_REGISTERED: {
            code: 'AUTH_DEVICE_NOT_REGISTERED',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Login failed because device is not registered',
            numCode: '234',
        },
        /**
         * @description Cannot login because required parameters are missing
         */
        AUTH_MISSING_PARAMETERS: {
            code: 'AUTH_MISSING_PARAMETERS',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Required parameters are missing',
            numCode: '122',
        },
        /**
         * @description AUTHENTICATION_REQUIRED
         * Authentication to be done
         * Can be caused by an expired access token
         */
        AUTHENTICATION_REQUIRED: {
            code: 'AUTHENTICATION_REQUIRED',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Full authentication is required to access this resource',
            numCode: '123',
        },
        /**
         * @description Sign up failure due to existing account
         */
        SIGNUP_FAILED_BECAUSE_EXISTING_ACCOUNT: {
            code: 'SIGNUP_FAILED_BECAUSE_EXISTING_ACCOUNT',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Sign up failure due to existing account',
            numCode: '124',
        },
        /**
         * @description AUTH_MISSING_COOKIE
         * Cannot login because cookie is missing from response
         */
        AUTH_MISSING_COOKIE: {
            code: 'AUTH_MISSING_COOKIE',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Cannot login because cookie is missing from response',
            numCode: '125',
        },

        /**
         * @description NO_ACCOUNT_IDS_FOR_CURRENT_USER
         * Can not get account IDs for this user
         */
        NO_ACCOUNT_IDS_FOR_CURRENT_USER: {
            code: 'NO_ACCOUNT_IDS_FOR_CURRENT_USER',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Can not get account IDs for this user',
            numCode: '126',
        },

        /**
         * @description Forgot password failure due to unexisting account
         */
        FORGOT_PASSWORD_FAILED_BECAUSE_UNEXISTING_ACCOUNT: {
            code: 'FORGOT_PASSWORD_FAILED_BECAUSE_UNEXISTING_ACCOUNT',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Forgot password failure due to unexisting account',
            numCode: '127',
        },

        /**
         * @description Any error for login via QRcode
         */
        FAILED_QR_CODE_VALIDATION: {
            code: 'FAILED_QR_CODE_VALIDATION',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'QRcode validation failed',
            numCode: '128',
        },

        /**
         * @description Error with device_code or user_code
         */
        FAILED_QR_CODE_VALIDATION_INVALID_CODE: {
            code: 'FAILED_QR_CODE_VALIDATION_INVALID_CODE',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'QRcode validation failed: invalid device_code or user_code',
            numCode: '129',
        },

        /**
         * @description Missing Device permissions
         */
        MISSING_DEVICE_PERMISSION: {
            code: 'MISSING_DEVICE_PERMISSION',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Missing device permission or data to be able to log in',
            numCode: '130',
        },

        /**
         **
         * USER/SESSION & DEVICE ERRORS - 200
         **
         */
        /**
         * @description DELETE_BACKEND_DEVICE_FAILED
         */
        DELETE_BACKEND_DEVICE_FAILED: {
            code: 'DELETE_BACKEND_DEVICE_FAILED',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Device Deletion Failed',
            numCode: '200',
        },
        /**
         * @description DEVICE_IN_ANOTHER_HOUSEHOLD
         */
        DEVICE_IN_ANOTHER_HOUSEHOLD: {
            code: 'DEVICE_IN_ANOTHER_HOUSEHOLD',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Device In Other Household',
            numCode: '201',
            playerAutoRetry: true,
        },
        /**
         * @description EMAIL_NOT_VERIFIED
         */
        EMAIL_NOT_VERIFIED: {
            code: 'EMAIL_NOT_VERIFIED',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Email Not Verified',
            numCode: '202',
        },
        /**
         * @description INVALID_PIN
         */
        INVALID_PIN: {
            code: 'INVALID_PIN',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Invalid Pin Code',
            numCode: '203',
        },
        /**
         * @description UPDATE_PIN_FAILED
         */
        UPDATE_PIN_FAILED: {
            code: 'UPDATE_PIN_FAILED',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Update Pin Code Failed',
            numCode: '205',
        },
        /**
         * @description LOGIN_FAILED
         */
        LOGIN_FAILED: {
            code: 'LOGIN_FAILED',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Login Failed',
            numCode: '206',
        },
        /**
         * @description LOGIN_INVALID_CREDENTIALS
         */
        LOGIN_INVALID_CREDENTIALS: {
            code: 'LOGIN_INVALID_CREDENTIALS',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Invalid Login Credentials',
            numCode: '207',
        },
        /**
         * @description LOGIN_REQUIRED
         */
        LOGIN_REQUIRED: {
            code: 'LOGIN_REQUIRED',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Login Required',
            numCode: '208',
        },
        /**
         * @description LOGIN_UNKNOWN_USER
         */
        LOGIN_UNKNOWN_USER: {
            code: 'LOGIN_UNKNOWN_USER',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Login Unknown User',
            numCode: '209',
        },
        /**
         * @description MAX_CONCURRENT_SESSIONS
         */
        MAX_CONCURRENT_SESSIONS: {
            code: 'MAX_CONCURRENT_SESSIONS',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Max Concurrent Sessions',
            numCode: '210',
            playerAutoRetry: false,
        },
        /**
         * @description MAX_DEVICES_EXCEEDED
         */
        MAX_DEVICES_EXCEEDED: {
            code: 'MAX_DEVICES_EXCEEDED',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Max Devices Exceeded',
            numCode: '211',
            playerAutoRetry: false,
        },
        /**
         * @description MAX_DEVICES_REACHED
         */
        MAX_DEVICES_REACHED: {
            code: 'MAX_DEVICES_REACHED',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Max Devices Reached',
            numCode: '212',
            playerAutoRetry: false,
        },
        /**
         * @description BACKEND_MAX_DEVICES_REACHED
         */
        BACKEND_MAX_DEVICES_REACHED: {
            code: 'BACKEND_MAX_DEVICES_REACHED',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Backend Max Devices Reached',
            numCode: '213',
            playerAutoRetry: true,
        },
        /**
         * @description USER_IS_BARRED
         */
        USER_IS_BARRED: {
            code: 'USER_IS_BARRED',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'User Is Barred',
            numCode: '214',
            playerAutoRetry: false,
        },
        /**
         * @description USER_ACCOUNT_TERMINATED
         * This issue occurs when the account has been finished or terminated by the Backend.
         * For some backend this error means the user is unable to login.
         * Otherwise, the user can login but can't use the TV Product
         * i.e.: Purchase, watch depending on the business logic managed by the backend for account terminated
         */
        USER_ACCOUNT_TERMINATED: {
            code: 'USER_ACCOUNT_TERMINATED',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'User Account Terminated/Canceled',
            numCode: '215',
        },
        /**
         * @description USER_PROFILE_RETRIEVAL_FAILED
         */
        USER_PROFILE_RETRIEVAL_FAILED: {
            code: 'USER_PROFILE_RETRIEVAL_FAILED',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Profile Retrieval Failed',
            numCode: '216',
        },
        /**
         * @description USER_PREFERENCE_RETRIEVAL_FAILED
         */
        USER_PREFERENCE_RETRIEVAL_FAILED: {
            code: 'USER_PREFERENCE_RETRIEVAL_FAILED',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Preference Retrieval Failed',
            numCode: '217',
        },
        /**
         * @description USER_PREFERENCE_UPDATE_FAILED
         */
        USER_PREFERENCE_UPDATE_FAILED: {
            code: 'USER_PREFERENCE_UPDATE_FAILED',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Preference Update Failed',
            numCode: '218',
        },
        /**
         * @description USER_PIN_UNSET
         */
        USER_PIN_UNSET: {
            code: 'USER_PIN_UNSET',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Unset User Pin',
            numCode: '219',
        },
        /**
         * @description SIGNUP_INVALID_CREDENTIALS
         */
        SIGNUP_INVALID_CREDENTIALS: {
            code: 'SIGNUP_INVALID_CREDENTIALS',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Sign Up Invalid Credentials',
            numCode: '220',
        },
        /**
         * @description SIGNUP_FAILED
         */
        SIGNUP_FAILED: {
            code: 'SIGNUP_FAILED',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Sign Up Failed',
            numCode: '221',
        },
        /**
         * @description MISSING_USER_ID
         */
        MISSING_USER_ID: {
            code: 'MISSING_USER_ID',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Missing User Id',
            numCode: '222',
        },
        /**
         * @description MISSING_ACCOUNT_ID
         */
        MISSING_ACCOUNT_ID: {
            code: 'MISSING_ACCOUNT_ID',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Missing Account Id',
            numCode: '223',
        },
        /**
         * @description
         */
        USER_CONTINUE_WATCHING_RETRIEVAL_FAILED: {
            code: 'USER_CONTINUE_WATCHING_RETRIEVAL_FAILED',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Continue Watching Retrieval failed',
            numCode: '224',
        },
        /**
         * @description
         */
        USER_CONTINUE_WATCHING_UPDATE_FAILED: {
            code: 'USER_CONTINUE_WATCHING_UPDATE_FAILED',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Continue Watching update failed (Setting a bookmark)',
            numCode: '225',
        },
        /**
         * @description
         */
        USER_SUBSCRIPTIONS_RETRIEVAL_FAILED: {
            code: 'USER_SUBSCRIPTIONS_RETRIEVAL_FAILED',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'User subscription retrieval failed',
            numCode: '226',
        },
        /**
         * @description USER_ACCOUNT_LOCKED
         * This issue could occurs after several attempt of login
         */
        USER_ACCOUNT_LOCKED: {
            code: 'USER_ACCOUNT_LOCKED',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'User Account Locked',
            numCode: '227',
        },
        /**
         * @description MISSING_TENANT_ID
         */
        MISSING_TENANT_ID: {
            code: 'MISSING_TENANT_ID',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Missing Tenant Id',
            numCode: '228',
        },
        /**
         * @description INVALID_PASSWORD
         */
        INVALID_PASSWORD: {
            code: 'INVALID_PASSWORD',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Invalid Password',
            numCode: '229',
        },
        /**
         * @description INVALID_ACCOUNT_ID
         */
        INVALID_ACCOUNT_ID: {
            code: 'INVALID_ACCOUNT_ID',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Invalid Account Id',
            numCode: '230',
        },
        /**
         * @description ACCOUNT_NOT_FOUND
         */
        ACCOUNT_NOT_FOUND: {
            code: 'ACCOUNT_NOT_FOUND',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Account not found',
            numCode: '231',
        },
        /**
         * @description ERROR_RETRIEVING_TV_SERVICES
         */
        ERROR_RETRIEVING_TV_SERVICES: {
            code: 'ERROR_RETRIEVING_TV_SERVICES',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Default error while retrieving TV Service',
            numCode: '232',
        },
        /**
         * @description ERROR_RESET_PIN
         */
        ERROR_RESET_PIN: {
            code: 'ERROR_RESET_PIN',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Unknown error while resetting the PIN',
            numCode: '233',
        },
        /**
         * @description DEVICE_TOKEN_MISMATCH
         */
        DEVICE_TOKEN_MISMATCH: {
            code: 'DEVICE_TOKEN_MISMATCH',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Device and Token do no match',
            numCode: '235', // 234 is up there, in auth error codes section
        },
        /**
         * @description ADD_DELETE_DEVICE_FAILED
         */
        ADD_DELETE_DEVICE_FAILED: {
            code: 'ADD_DELETE_DEVICE_FAILED',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Adding or deleting device failed',
            numCode: '236',
        },

        /**
         * @description DEVICE_TYPE_NOT_SUPPORTED
         */
        DEVICE_TYPE_NOT_SUPPORTED: {
            code: 'DEVICE_TYPE_NOT_SUPPORTED',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Device Type not supported',
            numCode: '237',
        },

        /**
         * @description Force backend not token based, but session based this error indicate that user needs to login again
         */
        USER_SESSION_NOT_FOUND_OR_TIMEOUT: {
            code: 'USER_SESSION_NOT_FOUND_OR_TIMEOUT',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'user session not found or timeout.',
            numCode: '238',
        },

        /**
         * @description Force backend not token based, but session based this error indicate that user needs to login again
         */
        UNAUTHORIZED_DUE_TO_SYSTEM_CLOCK_UNSYNCHRONIZED: {
            code: 'UNAUTHORIZED_DUE_TO_SYSTEM_CLOCK_UNSYNCHRONIZED',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'System clock is maladjusted and user cannot perform this action',
            numCode: '239',
        },

        /**
         * @description Force backend not token based, but session based this error indicate that user needs to login again
         */
        TOO_MANY_ANALYTICS_EVENT_SENT_TO_SERVER: {
            code: 'TOO_MANY_ANALYTICS_EVENT_SENT_TO_SERVER',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description:
                'When this error occurs, some event are not sent to server during a certain period to avoid to decrease the performances',
            numCode: '240',
        },
        /**
         * @description Phone number not registered or unknown in operator network
         */
        PHONE_NUMBER_UNKNOWN_BY_OPERATOR: {
            code: 'PHONE_NUMBER_UNKNOWN_BY_OPERATOR',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Phone number not registered or unknown in operator network',
            numCode: '241',
        },
        /**
         * @description FORGOT_PASSWORD_INVALID_CREDENTIALS
         */
        FORGOT_PASSWORD_INVALID_CREDENTIALS: {
            code: 'FORGOT_PASSWORD_INVALID_CREDENTIALS',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Forgot Password Invalid Credentials',
            numCode: '242',
        },
        /**
         * @description FORGOT_PASSWORD_FAILED
         */
        FORGOT_PASSWORD_FAILED: {
            code: 'FORGOT_PASSWORD_FAILED',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Forgot Password Failed',
            numCode: '243',
        },

        /**
         * @description Entered pin is expired
         */
        PIN_EXPIRED: {
            code: 'PIN_EXPIRED',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Entered pin is expired',
            numCode: '244',
        },

        /**
         * @description Error while retrieving account number for a user
         */
        ACCOUNT_NUMBER_RETRIEVING_ERROR: {
            code: 'ACCOUNT_NUMBER_RETRIEVING_ERROR',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Error while retrieving account number for a user',
            numCode: '245',
        },

        /**
         * @description You can generate a limited number of PINs in time
         */
        PIN_GENERATION_LIMIT: {
            code: 'PIN_GENERATION_LIMIT',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'You can generate a limited number of PINs in time',
            numCode: '246',
        },

        /**
         * @description Device has already bound, you can not bound another one
         */
        DEVICE_ALREADY_BOUND: {
            code: 'DEVICE_ALREADY_BOUND',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Device has already bound, you can not bound another one',
            numCode: '247',
        },

        /**
         * @description The address IP of the user is blaacklisted
         */
        USER_IP_BLACKLISTED: {
            code: 'USER_IP_BLACKLISTED',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'User IP is blacklisted',
            numCode: '248',
        },

        /**
         * @description Maximum login attempts reached, your account may be locked
         */
        MAXIMUM_LOGIN_ATTEMPTS_REACHED: {
            code: 'MAXIMUM_LOGIN_ATTEMPTS_REACHED',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Maximum login attempts reached, your account may be locked',
            numCode: '249',
        },

        /**
         * @description The account has been registered with another device. Cannot login
         */
        ACCOUNT_REGISTERED_WITH_OTHER_DEVICE_CANNOT_LOGIN: {
            code: 'ACCOUNT_REGISTERED_WITH_OTHER_DEVICE_CANNOT_LOGIN',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'The account has been registered with another device. Cannot login',
            numCode: '250',
        },

        /**
         * @description DEVICE_IS_SUSPENDED
         */
        DEVICE_IS_SUSPENDED: {
            code: 'DEVICE_IS_SUSPENDED',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Device Is Suspended',
            numCode: '251',
            playerAutoRetry: false,
        },

        /**
         * @description SERIAL_NUMBER_NOT_UNIQUE
         */
        SERIAL_NUMBER_NOT_UNIQUE: {
            code: 'SERIAL_NUMBER_NOT_UNIQUE',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Serial number of the device is not unique cannot login',
            numCode: '252',
            playerAutoRetry: false,
        },

        /**
         * @description SERIAL_NUMBER_INVALID
         */
        SERIAL_NUMBER_INVALID: {
            code: 'SERIAL_NUMBER_INVALID',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Serial number of the device is invalid',
            numCode: '253',
            playerAutoRetry: false,
        },

        /**
         * @description CANNOT_LOGIN_TO_CHANNEL_NAMESPACE
         */
        CANNOT_LOGIN_TO_CHANNEL_NAMESPACE: {
            code: 'CANNOT_LOGIN_TO_CHANNEL_NAMESPACE',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Cannot login to the channel namespace',
            numCode: '254',
            playerAutoRetry: false,
        },

        /**
         * @description CANNOT_LOGIN_BECAUSE_SERVER_IS_BUSY
         */
        CANNOT_LOGIN_BECAUSE_SERVER_IS_BUSY: {
            code: 'CANNOT_LOGIN_BECAUSE_SERVER_IS_BUSY',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Cannot login because the server is busy',
            numCode: '255',
            playerAutoRetry: false,
        },

        /**
         * @description SERVER_CANNOT_ADD_DRM_DEVICE
         */
        SERVER_CANNOT_ADD_DRM_DEVICE: {
            code: 'SERVER_CANNOT_ADD_DRM_DEVICE',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Cannot add DRM device',
            numCode: '256',
            playerAutoRetry: false,
        },

        /**
         * @description USER_CANNOT_PROFILE_SWITCH
         */
        USER_CANNOT_PROFILE_SWITCH: {
            code: 'USER_CANNOT_PROFILE_SWITCH',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Cannot switch to different profile',
            numCode: '257',
        },

        /**
         * @description USER_IS_SUSPENDED
         */
        USER_IS_SUSPENDED: {
            code: 'USER_IS_SUSPENDED',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'User Is Suspended',
            numCode: '258',
            playerAutoRetry: false,
        },

        /**
         * @description OTP_GENERATION_FAILED
         */
        OTP_GENERATION_FAILED: {
            code: 'OTP_GENERATION_FAILED',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'One time password cannot be generated by the server',
            numCode: '259',
            playerAutoRetry: false,
        },

        /**
         * @description OTP_VALIDATION_FAILED
         */
        OTP_VALIDATION_FAILED: {
            code: 'OTP_VALIDATION_FAILED',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'One time password cannot be validated by the server',
            numCode: '260',
            playerAutoRetry: false,
        },

        /**
         **
         * PLAYBACK ERRORS - 300
         **
         */
        /**
         * @description PLAYBACK_ERROR
         */
        PLAYBACK_ERROR: {
            code: 'PLAYBACK_ERROR',
            defaultContext: Constants.errorsContext.PLAYER_BACKEND,
            description: 'Generic Playback Error',
            numCode: '300',
            playerAutoRetry: true,
        },
        /**
         * @description PLAYER_DEVICE_NOT_SUPPORTED
         */
        PLAYER_DEVICE_NOT_SUPPORTED: {
            code: 'PLAYER_DEVICE_NOT_SUPPORTED',
            defaultContext: Constants.errorsContext.PLAYER_BACKEND,
            description: 'Playback Device Unsupported',
            numCode: '301',
            playerAutoRetry: true,
        },
        /**
         * @description PLAYER_SESSION_INCORRECT_ENTITLEMENT
         */
        PLAYER_SESSION_INCORRECT_ENTITLEMENT: {
            code: 'PLAYER_SESSION_INCORRECT_ENTITLEMENT',
            defaultContext: Constants.errorsContext.PLAYER_BACKEND,
            description: 'Playback Incorrect Entitlement',
            numCode: '302',
            playerAutoRetry: true,
        },
        /**
         * @description PLAYER_ENTITLEMENT_NO_SUCH_ASSET
         */
        PLAYER_ENTITLEMENT_NO_SUCH_ASSET: {
            code: 'PLAYER_ENTITLEMENT_NO_SUCH_ASSET',
            defaultContext: Constants.errorsContext.PLAYER_BACKEND,
            description: 'Playback No Asset',
            numCode: '303',
            playerAutoRetry: true,
        },
        /**
         * @description PLAYER_SESSION_MAX_STREAM_REACHED
         */
        PLAYER_SESSION_MAX_STREAM_REACHED: {
            code: 'PLAYER_SESSION_MAX_STREAM_REACHED',
            defaultContext: Constants.errorsContext.PLAYER_BACKEND,
            description: 'Playback Max Stream Reached',
            numCode: '304',
            playerAutoRetry: true,
        },
        /**
         * @description PLAYER_SESSION_ALREADY_EXIST
         */
        PLAYER_SESSION_ALREADY_EXIST: {
            code: 'PLAYER_SESSION_ALREADY_EXIST',
            defaultContext: Constants.errorsContext.PLAYER_BACKEND,
            description: 'Playback Session Exists',
            numCode: '305',
            playerAutoRetry: true,
        },
        /**
         * @description PLAYER_OFFER_IS_NOT_AVAILABLE
         */
        PLAYER_OFFER_IS_NOT_AVAILABLE: {
            code: 'PLAYER_OFFER_IS_NOT_AVAILABLE',
            defaultContext: Constants.errorsContext.PLAYER_BACKEND,
            description: 'Playback Offer Not Available',
            numCode: '306',
            playerAutoRetry: true,
        },
        /**
         * @description PLAYER_CONTENT_UNAVAILABLE_FOR_LOCATION
         */
        PLAYER_CONTENT_UNAVAILABLE_FOR_LOCATION: {
            code: 'PLAYER_CONTENT_UNAVAILABLE_FOR_LOCATION',
            defaultContext: Constants.errorsContext.PLAYER_BACKEND,
            description: 'Playback Content Not Available For Location',
            numCode: '307',
            playerAutoRetry: true,
        },
        /**
         * @description PLAYER_HOST_NOT_RUNNING
         */
        PLAYER_HOST_NOT_RUNNING: {
            code: 'PLAYER_HOST_NOT_RUNNING',
            defaultContext: Constants.errorsContext.PLAYER_BACKEND,
            description: 'Playback Host Not Running',
            numCode: '308',
            playerAutoRetry: true,
        },
        /**
         * @description PLAYER_ENTITLEMENT_REVOKED
         */
        PLAYER_ENTITLEMENT_REVOKED: {
            code: 'PLAYER_ENTITLEMENT_REVOKED',
            defaultContext: Constants.errorsContext.PLAYER_BACKEND,
            description: 'Playback Entitlement Revoked',
            numCode: '309',
            playerAutoRetry: false,
        },
        /**
         * @description PLAYER_MISSING_SESSION_ID_OR_ETAG
         */
        PLAYER_MISSING_SESSION_ID_OR_ETAG: {
            code: 'PLAYER_MISSING_SESSION_ID_OR_ETAG',
            defaultContext: Constants.errorsContext.PLAYER_BACKEND,
            description: 'Playback Missing ID',
            numCode: '310',
            playerAutoRetry: true,
        },
        /**
         * @description PARENTAL_LOCKED
         */
        PARENTAL_LOCKED: {
            code: 'PARENTAL_LOCKED',
            defaultContext: Constants.errorsContext.PLAYER_BACKEND,
            description: 'Playback blocked by Parental Locker',
            numCode: '311',
            playerAutoRetry: false,
        },
        /**
         * @description PLAYER_DEVICE_CREATION_FAILED
         */
        PLAYER_DEVICE_CREATION_FAILED: {
            code: 'PLAYER_DEVICE_CREATION_FAILED',
            defaultContext: Constants.errorsContext.PLAYER_BACKEND,
            description: 'Playback error due to the impossibility to create Device',
            numCode: '312',
            playerAutoRetry: true,
        },
        /**
         * @description PLAYER_DEVICE_NOT_FOUND
         */
        PLAYER_DEVICE_NOT_FOUND: {
            code: 'PLAYER_DEVICE_NOT_FOUND',
            defaultContext: Constants.errorsContext.PLAYER_BACKEND,
            description: 'Playback error due because of device not found',
            numCode: '313',
            playerAutoRetry: false,
        },
        /**
         * @description PLAYER_DEVICE_INVALID
         */
        PLAYER_DEVICE_INVALID: {
            code: 'PLAYER_DEVICE_INVALID',
            defaultContext: Constants.errorsContext.PLAYER_BACKEND,
            description: 'Playback blocked due to invalid device',
            numCode: '314',
            playerAutoRetry: false,
        },
        /**
         * @description PLAYER_SESSION_ERROR_DUE_TO_INVALID_PARAMETER
         */
        PLAYER_SESSION_ERROR_DUE_TO_INVALID_PARAMETER: {
            code: 'PLAYER_SESSION_ERROR_DUE_TO_INVALID_PARAMETER',
            defaultContext: Constants.errorsContext.PLAYER_BACKEND,
            description:
                'Cannot create a session due to invalid parameter (Header, Body, etc... of the request)',
            numCode: '315',
            playerAutoRetry: true,
        },
        /**
         * @description PLAYER_ENTITLEMENT_ERROR_DUE_TO_INVALID_PARAMETER
         */
        PLAYER_ENTITLEMENT_ERROR_DUE_TO_INVALID_PARAMETER: {
            code: 'PLAYER_ENTITLEMENT_ERROR_DUE_TO_INVALID_PARAMETER',
            defaultContext: Constants.errorsContext.PLAYER_BACKEND,
            description:
                'Cannot manage the entitlements due to invalid parameter (Header, Body, etc... of the request)',
            numCode: '316',
            playerAutoRetry: true,
        },
        /**
         * @description CONTENT_UNAVAILABLE_BECAUSE_OF_IP_MISMATCH
         */
        CONTENT_UNAVAILABLE_BECAUSE_OF_IP_MISMATCH: {
            code: 'CONTENT_UNAVAILABLE_BECAUSE_OF_IP_MISMATCH',
            defaultContext: Constants.errorsContext.PLAYER_BACKEND,
            description: 'Content cannot be launched due to IP mismatch',
            numCode: '317',
            playerAutoRetry: true,
        },
        /**
         * @description PLAYER_CONTENT_ID_ERROR
         */
        PLAYER_CONTENT_ID_ERROR: {
            code: 'PLAYER_CONTENT_ID_ERROR',
            defaultContext: Constants.errorsContext.PLAYER_BACKEND,
            description: 'Playback cannot be launched due to missing id',
            numCode: '318',
            playerAutoRetry: true,
        },
        /**
         * @description PLAYER_ROLLING_BUFFER_INVALID_TIME
         */
        PLAYER_ROLLING_BUFFER_INVALID_TIME: {
            code: 'PLAYER_ROLLING_BUFFER_INVALID_TIME',
            defaultContext: Constants.errorsContext.PLAYER_BACKEND,
            description: 'Player error due to invalid time for launch start over or catch up',
            numCode: '319',
            playerAutoRetry: true,
        },
        /**
         * @description Manifest or stream is corrupted or invalid
         */
        PLAYER_SESSION_ERROR_INVALID_STREAM: {
            code: 'PLAYER_SESSION_ERROR_INVALID_STREAM',
            defaultContext: Constants.errorsContext.PLAYER_BACKEND,
            description: 'Manifest or stream is corrupted or invalid',
            numCode: '320',
            playerAutoRetry: true,
        },
        /**
         * @description Manifest or segment not found
         */
        PLAYER_SESSION_ERROR_STREAM_NOT_FOUND: {
            code: 'PLAYER_SESSION_ERROR_STREAM_NOT_FOUND',
            defaultContext: Constants.errorsContext.PLAYER_BACKEND,
            description: 'Manifest or segment not found',
            numCode: '321',
            playerAutoRetry: true,
        },

        /**
         * @description Playback not found
         */
        PLAYBACK_NOT_FOUND: {
            code: 'PLAYBACK_NOT_FOUND',
            defaultContext: Constants.errorsContext.PLAYER_BACKEND,
            description: 'Playback not found',
            numCode: '322',
            playerAutoRetry: false,
        },

        /**
         * @description Manifest or segment not found
         */
        METADATA_MISSING_MANIFEST_FILE_FROM_BACKEND: {
            code: 'METADATA_MISSING_MANIFEST_FILE_FROM_BACKEND',
            defaultContext: Constants.errorsContext.PLAYER_BACKEND,
            description: 'Manifest file missing from the metadatas',
            numCode: '323',
            playerAutoRetry: true,
        },

        /**
         **
         * PLAYER ERRORS - 350-400
         **
         */
        /**
         * @description PLAYER_ENTITLEMENT_NO_DRM_FOUND
         */
        PLAYER_ENTITLEMENT_NO_DRM_FOUND: {
            code: 'PLAYER_ENTITLEMENT_NO_DRM_FOUND',
            defaultContext: Constants.errorsContext.PLAYER_REACT,
            description: 'React Native Video Error: Playback No DRM found',
            numCode: '350',
            playerAutoRetry: true,
        },

        /**
         * @description PLAYER_NETWORK_ERROR
         */
        PLAYER_NETWORK_ERROR: {
            code: 'PLAYER_NETWORK_ERROR',
            defaultContext: Constants.errorsContext.PLAYER_REACT,
            description: 'React Native Video Error: Network Error',
            numCode: '351',
            playerAutoRetry: true,
        },

        /**
         * @description PLAYER_DECODER_ERROR
         */
        PLAYER_DECODER_ERROR: {
            code: 'PLAYER_DECODER_ERROR',
            defaultContext: Constants.errorsContext.PLAYER_REACT,
            description: 'React Native Video Error: Decoder Error',
            numCode: '352',
            playerAutoRetry: true,
        },

        /**
         * @description PLAYER_INVALID_FORMAT
         */
        PLAYER_INVALID_FORMAT: {
            code: 'PLAYER_INVALID_FORMAT',
            defaultContext: Constants.errorsContext.PLAYER_REACT,
            description: 'React Native Video Error: Format Error',
            numCode: '353',
            playerAutoRetry: true,
        },

        /**
         * @description PLAYER_INVALID_STATE
         */
        PLAYER_INVALID_STATE: {
            code: 'PLAYER_INVALID_STATE',
            defaultContext: Constants.errorsContext.PLAYER_REACT,
            description: 'React Native Video Error: InvalidState',
            numCode: '354',
            playerAutoRetry: true,
        },

        /**
         * @description PLAYER_INVALID_STATE
         */
        PLAYER_CANCELED: {
            code: 'PLAYER_CANCELED',
            defaultContext: Constants.errorsContext.PLAYER_REACT,
            description: 'React Native Video Error: Canceled',
            numCode: '355',
            playerAutoRetry: true,
        },

        /**
         * @description PLAYER_TIMEOUT
         */
        PLAYER_TIMEOUT: {
            code: 'PLAYER_TIMEOUT',
            defaultContext: Constants.errorsContext.PLAYER_REACT,
            description: 'React Native Video Error: Timeout',
            numCode: '356',
            playerAutoRetry: true,
        },

        /**
         * @description PLAYER_PLAYBACK_RESTRICTED
         */
        PLAYER_PLAYBACK_RESTRICTED: {
            code: 'PLAYER_PLAYBACK_RESTRICTED',
            defaultContext: Constants.errorsContext.PLAYER_REACT,
            description: 'React Native Video Error: Restricted',
            numCode: '357',
            playerAutoRetry: true,
        },

        /**
         * @description PLAYER_DEVICE_NOT_IN_HOUSEHOLD
         */
        PLAYER_DEVICE_NOT_IN_HOUSEHOLD: {
            code: 'PLAYER_DEVICE_NOT_IN_HOUSEHOLD',
            defaultContext: Constants.errorsContext.PLAYER_REACT,
            description: 'React Native Video Error: DeviceNotInHouseHold',
            numCode: '358',
            playerAutoRetry: false,
        },
        /**
         * @description PLAYER_HTML5_ERROR
         */
        PLAYER_HTML5_ERROR: {
            code: 'PLAYER_HTML5_ERROR',
            defaultContext: Constants.errorsContext.PLAYER_REACT,
            description: 'React Native Video Error: Html5',
            numCode: '359',
            playerAutoRetry: true,
        },

        /**
         * @description PLAYER_VIDEO_NOT_PLAYABLE_IN_EMBDED_PLAYER
         */
        PLAYER_VIDEO_NOT_PLAYABLE_IN_EMBDED_PLAYER: {
            code: 'PLAYER_VIDEO_NOT_PLAYABLE_IN_EMBDED_PLAYER',
            defaultContext: Constants.errorsContext.PLAYER_REACT,
            description: 'React Native Video Error: NotPlayerInEmbdedPlayer',
            numCode: '360',
            playerAutoRetry: false,
        },

        /**
         * @description PLAYER_WEBVIEW_NOT_AVAILABLE
         */
        PLAYER_WEBVIEW_NOT_AVAILABLE: {
            code: 'PLAYER_WEBVIEW_NOT_AVAILABLE',
            defaultContext: Constants.errorsContext.PLAYER_REACT,
            description: 'React Native Video Error: NoWebview',
            numCode: '361',
            playerAutoRetry: true,
        },
        /**
         * @description PLAYER_ERROR_DUE_TO_INVALID_TOKEN
         */
        PLAYER_ERROR_DUE_TO_INVALID_TOKEN: {
            code: 'PLAYER_ERROR_DUE_TO_INVALID_TOKEN',
            defaultContext: Constants.errorsContext.PLAYER_BACKEND,
            description: 'Error starting the playback due to invalid credentials',
            numCode: '362',
            playerAutoRetry: true,
        },
        /**
         * @description PLAYER_DRM_CANNOT_EXTRACT_KID
         */
        PLAYER_DRM_CANNOT_EXTRACT_KID: {
            code: 'PLAYER_DRM_CANNOT_EXTRACT_KID',
            defaultContext: Constants.errorsContext.PLAYER_REACT,
            description: 'DRM: Playback Cannot extract KID from Manifest File',
            numCode: '363',
            playerAutoRetry: true,
        },
        /**
         * @description PLAYER_DRM_PROVISION_FAILURE
         */
        PLAYER_DRM_PROVISION_FAILURE: {
            code: 'PLAYER_DRM_PROVISION_FAILURE',
            defaultContext: Constants.errorsContext.PLAYER_REACT,
            description: 'React Native Video Error: DRM not provisionned',
            numCode: '364',
            playerAutoRetry: true,
        },
        /**
         * @description PLAYER_DRM_DECRYPTION_FAILURE
         */
        PLAYER_DRM_DECRYPTION_FAILURE: {
            code: 'PLAYER_DRM_DECRYPTION_FAILURE',
            defaultContext: Constants.errorsContext.PLAYER_REACT,
            description: 'React Native Video Error: DRM Decryption failure',
            numCode: '365',
            playerAutoRetry: true,
        },
        /**
         * @description PLAYER_DRM_NOT_SUPPORTED
         */
        PLAYER_DRM_NOT_SUPPORTED: {
            code: 'PLAYER_DRM_NOT_SUPPORTED',
            defaultContext: Constants.errorsContext.PLAYER_REACT,
            description: 'React Native Video Error: DRM not supported by the device',
            numCode: '366',
            playerAutoRetry: true,
        },
        /**
         * @description PLAYER_DRM_DATA_INIT_FAILURE
         */
        PLAYER_DRM_DATA_INIT_FAILURE: {
            code: 'PLAYER_DRM_DATA_INIT_FAILURE',
            defaultContext: Constants.errorsContext.PLAYER_REACT,
            description: 'React Native Video Error: DRM data init failure',
            numCode: '367',
            playerAutoRetry: true,
        },
        /**
         * @description PLAYER_DRM_KEY_INVALID
         */
        PLAYER_DRM_KEY_INVALID: {
            code: 'PLAYER_DRM_KEY_INVALID',
            defaultContext: Constants.errorsContext.PLAYER_REACT,
            description: 'React Native Video Error: DRM key invalid',
            numCode: '368',
            playerAutoRetry: true,
        },
        /**
         * @description PLAYER_DRM_KEY_EXPIRED
         */
        PLAYER_DRM_KEY_EXPIRED: {
            code: 'PLAYER_DRM_KEY_EXPIRED',
            defaultContext: Constants.errorsContext.PLAYER_REACT,
            description: 'React Native Video Error: DRM key expired',
            numCode: '369',
            playerAutoRetry: true,
        },
        /**
         * @description PLAYER_DRM_FETCH_FAILURE
         */
        PLAYER_DRM_FETCH_FAILURE: {
            code: 'PLAYER_DRM_FETCH_FAILURE',
            defaultContext: Constants.errorsContext.PLAYER_REACT,
            description: 'React Native Video Error: DRM fetch failure',
            numCode: '370',
            playerAutoRetry: true,
        },
        /**
         * @description PLAYER_DRM_SESSION_SETUP_FAILURE
         */
        PLAYER_DRM_SESSION_SETUP_FAILURE: {
            code: 'PLAYER_DRM_SESSION_SETUP_FAILURE',
            defaultContext: Constants.errorsContext.PLAYER_REACT,
            description: 'React Native Video Error: DRM session setup failure',
            numCode: '371',
            playerAutoRetry: true,
        },
        /**
         * @description PLAYER_PERMANENT_DRM_ERROR
         */
        PLAYER_PERMANENT_DRM_ERROR: {
            code: 'PLAYER_PERMANENT_DRM_ERROR',
            defaultContext: Constants.errorsContext.PLAYER_REACT,
            description: 'React Native Video Error: Permanent DRM Error',
            numCode: '372',
            playerAutoRetry: true,
        },
        /**
         * @description PLAYER_NATIVE_SESSION_CREATION_FAILURE
         */
        PLAYER_NATIVE_SESSION_CREATION_FAILURE: {
            code: 'PLAYER_NATIVE_SESSION_CREATION_FAILURE',
            defaultContext: Constants.errorsContext.PLAYER_REACT,
            description: 'React Native Video Error: Session creation failure',
            numCode: '373',
            playerAutoRetry: true,
        },
        /**
         * @description PLAYER_NATIVE_SESSION_RENEWAL_FAILURE
         */
        PLAYER_NATIVE_SESSION_RENEWAL_FAILURE: {
            code: 'PLAYER_NATIVE_SESSION_RENEWAL_FAILURE',
            defaultContext: Constants.errorsContext.PLAYER_REACT,
            description: 'React Native Video Error: Session renewal failure',
            numCode: '374',
            playerAutoRetry: true,
        },
        /**
         * @description PLAYER_NATIVE_SESSION_TEARDOWN_FAILURE
         */
        PLAYER_NATIVE_SESSION_TEARDOWN_FAILURE: {
            code: 'PLAYER_NATIVE_SESSION_TEARDOWN_FAILURE',
            defaultContext: Constants.errorsContext.PLAYER_REACT,
            description: 'React Native Video Error: Session terdown failure',
            numCode: '375',
            playerAutoRetry: true,
        },
        /**
         * @description PLAYER_DEVICE_NOT_PROVISIONNED
         */
        PLAYER_DEVICE_NOT_PROVISIONNED: {
            code: 'PLAYER_DEVICE_NOT_PROVISIONNED',
            defaultContext: Constants.errorsContext.PLAYER_REACT,
            description: 'React Native Video Error: Device Not Provisionned',
            numCode: '376',
            playerAutoRetry: false,
        },

        /**
         * @description PLAYER_SESSION_ERROR_INVALID_ACCOUNT
         */
        PLAYER_SESSION_ERROR_INVALID_ACCOUNT: {
            code: 'PLAYER_SESSION_ERROR_INVALID_ACCOUNT',
            defaultContext: Constants.errorsContext.PLAYER_REACT,
            description: 'React Native Video Error: Session error unknown account',
            numCode: '377',
            playerAutoRetry: false,
        },

        /**
         * @description PLAYER_SESSION_ERROR_INVALID_CONFIG
         */
        PLAYER_SESSION_ERROR_INVALID_CONFIG: {
            code: 'PLAYER_SESSION_ERROR_INVALID_CONFIG',
            defaultContext: Constants.errorsContext.PLAYER_REACT,
            description: 'React Native Video Error: Session error invalid config',
            numCode: '378',
            playerAutoRetry: false,
        },

        /**
         * @description PLAYER_SESSION_ERROR_EXPIRATION_TIME
         */
        PLAYER_SESSION_ERROR_EXPIRATION_TIME: {
            code: 'PLAYER_SESSION_ERROR_EXPIRATION_TIME',
            defaultContext: Constants.errorsContext.PLAYER_REACT,
            description: 'React Native Video Error: Invalid expiration time',
            numCode: '379',
            playerAutoRetry: false,
        },

        /**
         * @description PLAYER_SESSION_ERROR_SESSION_DISABLED
         */
        PLAYER_SESSION_ERROR_SESSION_DISABLED: {
            code: 'PLAYER_SESSION_ERROR_SESSION_DISABLED',
            defaultContext: Constants.errorsContext.PLAYER_REACT,
            description: 'React Native Video Error: Session management disabled',
            numCode: '380',
            playerAutoRetry: false,
        },
        /**
         * @description PLAYER_ERROR_DRM_REVOKED
         */
        PLAYER_ERROR_DRM_REVOKED: {
            code: 'PLAYER_ERROR_DRM_REVOKED',
            defaultContext: Constants.errorsContext.PLAYER_REACT,
            description:
                'React Native Video Error: Caused by the device having revoked DRM privileges.',
            numCode: '381',
            playerAutoRetry: true,
        },
        /**
         * @description PLAYER_ERROR_DRM_INCOMPATIBLE_CONTENT
         */
        PLAYER_ERROR_DRM_INCOMPATIBLE_CONTENT: {
            code: 'PLAYER_ERROR_DRM_INCOMPATIBLE_CONTENT',
            defaultContext: Constants.errorsContext.PLAYER_REACT,
            description:
                'React Native Video Error: Caused by attempting to play incompatible DRM-protected content.',
            numCode: '382',
            playerAutoRetry: true,
        },
        /**
         * @description PLAYER_ERROR_DRM_INCOMPATIBLE_POLICY
         */
        PLAYER_ERROR_DRM_INCOMPATIBLE_POLICY: {
            code: 'PLAYER_ERROR_DRM_INCOMPATIBLE_POLICY',
            defaultContext: Constants.errorsContext.PLAYER_REACT,
            description:
                'React Native Video Error: Caused by an operation being disallowed by a license policy.',
            numCode: '383',
            playerAutoRetry: true,
        },
        /**
         * @description PLAYER_ERROR_DRM_AUDIO_TRACK_INIT
         */
        PLAYER_ERROR_DRM_AUDIO_TRACK_INIT: {
            code: 'PLAYER_ERROR_DRM_AUDIO_TRACK_INIT',
            defaultContext: Constants.errorsContext.PLAYER_REACT,
            description:
                'React Native Video Error: Caused by an AudioTrack initialization failure.',
            numCode: '384',
            playerAutoRetry: true,
        },
        /**
         * @description PLAYER_ERROR_SLIDING_BEHIND_LIVE_WINDOW
         */
        PLAYER_ERROR_SLIDING_BEHIND_LIVE_WINDOW: {
            code: 'PLAYER_ERROR_SLIDING_BEHIND_LIVE_WINDOW',
            defaultContext: Constants.errorsContext.PLAYER_REACT,
            description:
                'React Native Video Error: Caused by the loading position falling behind the sliding window of available live content.',
            numCode: '385',
            playerAutoRetry: true,
        },
        /**
         * @description PLAYER_ERROR_DECODER_EXCEEDS_DEVICE_CAPABILITIES
         */
        PLAYER_ERROR_DECODER_EXCEEDS_DEVICE_CAPABILITIES: {
            code: 'PLAYER_ERROR_DECODER_EXCEEDS_DEVICE_CAPABILITIES',
            defaultContext: Constants.errorsContext.PLAYER_REACT,
            description:
                'React Native Video Error: Caused by trying to decode content whose format exceeds the capabilities of the device.',
            numCode: '386',
            playerAutoRetry: true,
        },
        /**
         * @description PLAYER_ERROR_NETWORK_CLEARTEXT_NOT_PERMITTED
         */
        PLAYER_ERROR_NETWORK_CLEARTEXT_NOT_PERMITTED: {
            code: 'PLAYER_ERROR_NETWORK_CLEARTEXT_NOT_PERMITTED',
            defaultContext: Constants.errorsContext.PLAYER_REACT,
            description:
                'React Native Video Error: Caused by the player trying to access cleartext HTTP traffic (meaning http:// rather than https://) when the app Network Security Configuration does not permit it.',
            numCode: '387',
            playerAutoRetry: true,
        },
        /**
         * @description PLAYER_ERROR_NETWORK_INVALID_CONTENT_TYPE
         */
        PLAYER_ERROR_NETWORK_INVALID_CONTENT_TYPE: {
            code: 'PLAYER_ERROR_NETWORK_INVALID_CONTENT_TYPE',
            defaultContext: Constants.errorsContext.PLAYER_REACT,
            description:
                'React Native Video Error: Caused by a server returning a resource with an invalid "Content-Type" HTTP header value.',
            numCode: '388',
            playerAutoRetry: true,
        },
        /**
         * @description PLAYER_ERROR_SERVER_TIMEOUT
         */
        PLAYER_ERROR_SERVER_TIMEOUT: {
            code: 'PLAYER_ERROR_SERVER_TIMEOUT',
            defaultContext: Constants.errorsContext.PLAYER_REACT,
            description:
                'React Native Video Error: Caused by a network timeout, meaning the server is taking too long to fulfill a request.',
            numCode: '389',
            playerAutoRetry: true,
        },
        /**
         * @description PLAYER_ERROR_DATA_IO
         */
        PLAYER_ERROR_DATA_IO: {
            code: 'PLAYER_ERROR_DATA_IO',
            defaultContext: Constants.errorsContext.PLAYER_REACT,
            description: 'React Native Video Error: Caused by a player In and Out',
            numCode: '390',
            playerAutoRetry: true,
        },
        /**
         * @description PLAYER_CONTENT_UNAUTHORIZED
         */
        PLAYER_CONTENT_UNAUTHORIZED: {
            code: 'PLAYER_CONTENT_UNAUTHORIZED',
            defaultContext: Constants.errorsContext.PLAYER_REACT,
            description: 'Content Unauthorized',
            numCode: '391',
            playerAutoRetry: true,
        },
        /**
         * @description PLAYER_DEVICE_UNAUTHORIZED
         */
        PLAYER_DEVICE_UNAUTHORIZED: {
            code: 'PLAYER_DEVICE_UNAUTHORIZED',
            defaultContext: Constants.errorsContext.PLAYER_REACT,
            description: 'Player device unauthorized',
            numCode: '392',
            playerAutoRetry: true,
        },
        /**
         * @description PLAYER_CONTENT_UNAVAILABLE
         */
        PLAYER_CONTENT_UNAVAILABLE: {
            code: 'PLAYER_CONTENT_UNAVAILABLE',
            defaultContext: Constants.errorsContext.PLAYER_REACT,
            description: 'Player: Content unavailable',
            numCode: '393',
            playerAutoRetry: true,
        },
        /**
         * @description PLAYER_DEVICE_UNAUTHORIZED
         */
        PLAYER_DEVICE_USED_BY_ANOTHER_APP: {
            code: 'PLAYER_DEVICE_USED_BY_ANOTHER_APP',
            defaultContext: Constants.errorsContext.PLAYER_REACT,
            description: 'Player device used by another app',
            numCode: '394',
            playerAutoRetry: true,
        },
        /**
         * @description PLAYER_VIDEO_NOT_SUPPORTED
         */
        PLAYER_VIDEO_NOT_SUPPORTED: {
            code: 'PLAYER_VIDEO_NOT_SUPPORTED',
            defaultContext: Constants.errorsContext.PLAYER_REACT,
            description: 'Player video not supported',
            numCode: '395',
            playerAutoRetry: true,
        },
        /**
         * @description PLAYER_VIDEO_CAPACITY_EXCEEDED
         */
        PLAYER_VIDEO_CAPACITY_EXCEEDED: {
            code: 'PLAYER_VIDEO_CAPACITY_EXCEEDED',
            defaultContext: Constants.errorsContext.PLAYER_REACT,
            description: 'Player video capacity exceeded',
            numCode: '396',
            playerAutoRetry: true,
        },
        /**
         * @description PLAYER_DISPLAY_ERROR
         */
        PLAYER_DISPLAY_ERROR: {
            code: 'PLAYER_DISPLAY_ERROR',
            defaultContext: Constants.errorsContext.PLAYER_REACT,
            description: 'Player display error',
            numCode: '397',
            playerAutoRetry: true,
        },
        /**
         * @description PLAYER_SESSION_ERROR
         */
        PLAYER_SESSION_ERROR: {
            code: 'PLAYER_SESSION_ERROR',
            defaultContext: Constants.errorsContext.PLAYER_REACT,
            description: 'Player session error',
            numCode: '398',
            playerAutoRetry: true,
        },
        /**
         * @description PLAYER_CDN_UNKNOWN_ERROR
         */
        PLAYER_CDN_UNKNOWN_ERROR: {
            code: 'PLAYER_CDN_UNKNOWN_ERROR',
            defaultContext: Constants.errorsContext.PLAYER_REACT,
            description: 'Player: CDN Unknown Error',
            numCode: '399',
            playerAutoRetry: true,
        },
        /**
         * @description PLAYER_CDN_URL_NOT_FOUND
         */
        PLAYER_CDN_URL_NOT_FOUND: {
            code: 'PLAYER_CDN_URL_NOT_FOUND',
            defaultContext: Constants.errorsContext.PLAYER_REACT,
            description: 'Player: CDN Url Not Found',
            numCode: '450',
            playerAutoRetry: true,
        },
        /**
         * @description PLAYER_CDN_RESPONSE_UNREADABLE
         */
        PLAYER_CDN_RESPONSE_UNREADABLE: {
            code: 'PLAYER_CDN_RESPONSE_UNREADABLE',
            defaultContext: Constants.errorsContext.PLAYER_REACT,
            description: 'Player: CDN Response unreadable Error',
            numCode: '451',
            playerAutoRetry: true,
        },

        /**
         * @description PLAYER_CDN_SERVICE_NOT_AVAILABLE
         */
        PLAYER_CDN_SERVICE_NOT_AVAILABLE: {
            code: 'PLAYER_CDN_SERVICE_NOT_AVAILABLE',
            defaultContext: Constants.errorsContext.PLAYER_REACT,
            description: 'Player: CDN Service not Available Error',
            numCode: '452',
            playerAutoRetry: true,
        },

        /**
         * @description PLAYER_CDN_CHANNEL_NOT_AVAILABLE
         */
        PLAYER_CDN_CHANNEL_NOT_AVAILABLE: {
            code: 'PLAYER_CDN_CHANNEL_NOT_AVAILABLE',
            defaultContext: Constants.errorsContext.PLAYER_REACT,
            description: 'Player: CDN Channel not Available Error',
            numCode: '453',
            playerAutoRetry: true,
        },

        /**
         * @description PLAYER_NANO_CDN_NOT_ALLOWED_TO_CONNECT_ON_WIFI
         */
        PLAYER_NANO_CDN_NOT_ALLOWED_TO_CONNECT_ON_WIFI: {
            code: 'PLAYER_NANO_CDN_NOT_ALLOWED_TO_CONNECT_ON_WIFI',
            defaultContext: Constants.errorsContext.PLAYER_REACT,
            description: 'Player: NANO CDN not Allowed to connect on WIFI Error',
            numCode: '454',
            playerAutoRetry: true,
        },

        /**
         * @description PLAYER_CDN_API_ERROR
         */
        PLAYER_CDN_API_ERROR: {
            code: 'PLAYER_CDN_API_ERROR',
            defaultContext: Constants.errorsContext.PLAYER_REACT,
            description: 'Player: CDN API Error',
            numCode: '455',
            playerAutoRetry: true,
        },

        /**
         * @description PLAYER_CDN_OPERATION_ALREADY_DONE
         */
        PLAYER_CDN_OPERATION_ALREADY_DONE: {
            code: 'PLAYER_CDN_OPERATION_ALREADY_DONE',
            defaultContext: Constants.errorsContext.PLAYER_REACT,
            description: 'Player: CDN Operattion already Error',
            numCode: '456',
            playerAutoRetry: true,
        },
        /**
         * @description PLAYER_AUDIO_DECODER_ERROR
         */
        PLAYER_AUDIO_DECODER_ERROR: {
            code: 'PLAYER_AUDIO_DECODER_ERROR',
            defaultContext: Constants.errorsContext.PLAYER_REACT,
            description: 'React Native Audio Error: Audio Decoder Error',
            numCode: '457',
            playerAutoRetry: false,
        },

        //////////NEW
        /**
         **
         * NETWORK ERRORS - 400-500
         * AND 2O4 (HTTP)
         **
         */
        /**
         * @description HTTP_204_NO_CONTENT
         */
        HTTP_204: {
            code: 'HTTP_204',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Http No Content',
            numCode: '204',
        },
        /**
         * @description HTTP_400_BAD_REQUEST
         */
        HTTP_400_BAD_REQUEST: {
            code: 'HTTP_400_BAD_REQUEST',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Http Bad Request',
            numCode: '400',
        },
        /**
         * @description HTTP_401_UNAUTHORIZED
         */
        HTTP_401_UNAUTHORIZED: {
            code: 'HTTP_401_UNAUTHORIZED',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Http Unauthorized',
            numCode: '401',
        },
        /**
         * @description HTTP_403_FORBIDDEN
         */
        HTTP_403_FORBIDDEN: {
            code: 'HTTP_403_FORBIDDEN',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Http Forbidden',
            numCode: '403',
        },
        /**
         * @description HTTP_404
         */
        HTTP_404: {
            code: 'HTTP_404',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Http Network',
            numCode: '404',
        },
        /**
         * @description INVALID_REQUEST_PAYLOAD
         */
        INVALID_REQUEST_PAYLOAD: {
            code: 'INVALID_REQUEST_PAYLOAD',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Invalid parameter provided in the payload or your request',
            numCode: '405',
        },
        /**
         * @description INVALID_REQUEST_HEADER
         */
        INVALID_REQUEST_HEADER: {
            code: 'INVALID_REQUEST_HEADER',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Invalid header provided in the payload or your request',
            numCode: '406',
        },
        /**
         * @description COMMUNICATION_ERROR_WITH_EXTERNAL_BSS
         */
        EXTERNAL_BSS_CANNOT_BE_REACHED: {
            code: 'EXTERNAL_BSS_CANNOT_BE_REACHED',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'External BSS cannot be reached',
            numCode: '407',
        },

        /**
         * @description HTTP_500_INTERNAL_SERVER_ERROR
         */
        HTTP_500_INTERNAL_SERVER_ERROR: {
            code: 'HTTP_500_INTERNAL_SERVER_ERROR',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Http Internal Server',
            numCode: '500',
        },
        /**
         * @description REQUEST_TIMEOUT
         */
        REQUEST_TIMEOUT: {
            code: 'REQUEST_TIMEOUT',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Request Timeout',
            numCode: '501',
        },
        /**
         * @description URL_ERROR
         */
        URL_ERROR: {
            code: 'URL_ERROR',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'URL Error',
            numCode: '502',
        },

        /**
         **
         * CATALOG & CONTENT ERRORS - 600
         **
         */
        /**
         * @description INVALID_CONTENT_PAGE
         * Unable to retrieve content from a dedicated page
         */
        INVALID_CONTENT_PAGE: {
            code: 'INVALID_CONTENT_PAGE',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Invalid Content Page',
            numCode: '600',
        },
        /**
         * @description INVALID_EMI_CONFIG
         */
        INVALID_EMI_CONFIG: {
            code: 'INVALID_EMI_CONFIG',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Invalid EMI Config',
            numCode: '601',
        },
        /**
         * @description MISSING_MEDIA_FILES
         */
        MISSING_MEDIA_FILES: {
            code: 'MISSING_MEDIA_FILES',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Missing Media Files',
            numCode: '602',
        },
        /**
         * @description MISSING_PRODUCT
         */
        MISSING_PRODUCT: {
            code: 'MISSING_PRODUCT',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Missing Product',
            numCode: '603',
        },
        /**
         * @description MISSING_ENTITLEMENT
         */
        MISSING_ENTITLEMENT: {
            code: 'MISSING_ENTITLEMENT',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Missing Entitlement',
            numCode: '604',
        },
        /**
         * @description UNAUTHORIZED_CONTENT
         * Content page unauthorized to be retrieved
         */
        UNAUTHORIZED_CONTENT: {
            code: 'UNAUTHORIZED_CONTENT',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Unauthorized Content',
            numCode: '605',
        },
        /**
         * @description PLATFORM_CONFIG_PARSING_ERROR
         */
        PLATFORM_CONFIG_PARSING_ERROR: {
            code: 'PLATFORM_CONFIG_PARSING_ERROR',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Platform Config Parsing',
            numCode: '606',
        },
        /**
         * @description
         */
        MISSING_EMI_CONFIG: {
            code: 'MISSING_EMI_CONFIG',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Missing EMI Configuration',
            numCode: '607',
        },
        /**
         * @description UNSUPPORTED_COUNTRY_CODE
         */
        UNSUPPORTED_COUNTRY_CODE: {
            code: 'UNSUPPORTED_COUNTRY_CODE',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Unsupported country code',
            numCode: '608',
        },
        /**
         * @description INVALID_OFFER
         */
        INVALID_OFFER: {
            code: 'INVALID_OFFER',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Invalid offer',
            numCode: '609',
        },
        /**
         * @description SERVICE_UNAVAILABLE
         */
        SERVICE_UNAVAILABLE: {
            code: 'SERVICE_UNAVAILABLE',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'service unavailable minVersion',
            numCode: '610',
        },
        /**
         **
         * PURCHASE & SUBSCRIPTION ERRORS - 700
         **
         */
        /**
         * @description PURCHASE_FAILED
         * Generic purchase error
         */
        PURCHASE_FAILED: {
            code: 'PURCHASE_FAILED',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Purchase Failed',
            numCode: '700',
        },
        /*
         * @description Purchase failed because account not found
         */
        PURCHASE_FAILED_BECAUSE_ACCOUNT_NOT_FOUND: {
            code: 'PURCHASE_FAILED_BECAUSE_ACCOUNT_NOT_FOUND',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Purchase failed because account not found in BSS',
            numCode: '701',
        },
        /*
         * @description Purchase failed because account not active
         */
        PURCHASE_FAILED_BECAUSE_ACCOUNT_NOT_ACTIVE: {
            code: 'PURCHASE_FAILED_BECAUSE_ACCOUNT_NOT_ACTIVE',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Purchase failed because account not active',
            numCode: '702',
        },
        /**
         * @description PURCHASE_FAILED_DUE_TO_MISSING_CURRENCY
         * This issue occurs when the user try to purchase
         * Error due to inconsistent currency configured in the backend
         */
        PURCHASE_FAILED_DUE_TO_MISSING_CURRENCY: {
            code: 'PURCHASE_FAILED_DUE_TO_MISSING_CURRENCY',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Purchase Failed Missing Currency',
            numCode: '703',
        },
        /*
         * @description Purchase failed because account is blacklisted
         */
        PURCHASE_FAILED_BECAUSE_ACCOUNT_BLACKLISTED: {
            code: 'PURCHASE_FAILED_BECAUSE_ACCOUNT_BLACKLISTED',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Purchase failed because account is blacklisted',
            numCode: '704',
        },
        /**
         * @description SUBSCRIPTION_ACTIVATION_FAILED
         */
        SUBSCRIPTION_ACTIVATION_FAILED: {
            code: 'SUBSCRIPTION_ACTIVATION_FAILED',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Subscription Activation Failed',
            numCode: '705',
        },
        /**
         * @description UNSUBSCRIBE_FAILED
         */
        UNSUBSCRIBE_FAILED: {
            code: 'UNSUBSCRIBE_FAILED',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Unsubscribe Failed',
            numCode: '706',
        },
        /**
         * @description UNSUBSCRIBE_ALREADY_DONE
         */
        UNSUBSCRIBE_ALREADY_DONE: {
            code: 'UNSUBSCRIBE_ALREADY_DONE',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Unsubscribe Already Done',
            numCode: '707',
        },
        /**
         * @description PURCHASE_FAILED_DUE_EXISTING_ENTITLEMENT
         */
        PURCHASE_FAILED_DUE_EXISTING_ENTITLEMENT: {
            code: 'PURCHASE_FAILED_DUE_EXISTING_ENTITLEMENT',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description:
                'Purchase failed because user has already purchase/subscribe to this content',
            numCode: '708',
        },
        /**
         * @description PURCHASE_FAILED_DUE_TRANSACTION_LIMIT_REACHED
         */
        PURCHASE_FAILED_DUE_TRANSACTION_LIMIT_REACHED: {
            code: 'PURCHASE_FAILED_DUE_TRANSACTION_LIMIT_REACHED',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description:
                'Purchase failed because the user has reached the limit of transaction for this content',
            numCode: '709',
        },
        /**
         * @description PURCHASE_FAILED_DUE_ACCOUNT_CONFIGURATION
         */
        PURCHASE_FAILED_DUE_ACCOUNT_CONFIGURATION: {
            code: 'PURCHASE_FAILED_DUE_ACCOUNT_CONFIGURATION',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Purchase failed due to account configuration mismatch',
            numCode: '710',
        },
        /**
         * @description PURCHASE_FAILED_DUE_INVALID_PARAMETERS
         */
        PURCHASE_FAILED_DUE_INVALID_PARAMETERS: {
            code: 'PURCHASE_FAILED_DUE_INVALID_PARAMETERS',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Purchase failed due to invalid parameters in the request (Header, Body)',
            numCode: '711',
        },
        /**
         * @description PURCHASE_FAILED_DUE_INVALID_OFFER
         */
        PURCHASE_FAILED_DUE_INVALID_OFFER: {
            code: 'PURCHASE_FAILED_DUE_INVALID_OFFER',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Purchase failed due to invalid offer configuration',
            numCode: '712',
        },
        /**
         * @description PURCHASE_FAILED_DUE_TO_MISSING_PAYMENT_METHOD
         */
        PURCHASE_FAILED_DUE_TO_MISSING_PAYMENT_METHOD: {
            code: 'PURCHASE_FAILED_DUE_TO_MISSING_PAYMENT_METHOD',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Purchase failed due to missing payment method',
            numCode: '713',
        },
        /**
         * @description PURCHASE_FAILED_BECAUSE_USER_DID_NOT_VALIDATE_THE_PURCHASE_ON_TIME
         */
        PURCHASE_FAILED_BECAUSE_USER_DID_NOT_VALIDATE_THE_PURCHASE_ON_TIME: {
            code: 'PURCHASE_FAILED_BECAUSE_USER_DID_NOT_VALIDATE_THE_PURCHASE_ON_TIME',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description:
                'Purchase failed because the user did not validate the purchase on time with a secondary device',
            numCode: '714',
        },
        /*
         * @description Purchase failed due to discount conflict for this account
         */
        PURCHASE_FAILED_DUE_TO_DISCOUNT_CONFLICT: {
            code: 'PURCHASE_FAILED_DUE_TO_DISCOUNT_CONFLICT',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Purchase failed due to discount conflict for this account',
            numCode: '715',
        },
        /*
         * @description Purchase failed due to existing contract for this account (not similar to already entitled)
         */
        PURCHASE_FAILED_DUE_TO_EXISTING_CONTRACT: {
            code: 'PURCHASE_FAILED_DUE_TO_EXISTING_CONTRACT',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Purchase failed due to existing contract for this account',
            numCode: '716',
        },
        /*
         * @description Purchase failed because product is not configured for inpulse purchase
         */
        PURCHASE_FAILED_BECAUSE_PRODUCT_NOT_CONFIGURED_FOR_IMPULSE_PURCHASE: {
            code: 'PURCHASE_FAILED_BECAUSE_PRODUCT_NOT_CONFIGURED_FOR_IMPULSE_PURCHASE',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Purchase failed because product is not configured for inpulse purchase',
            numCode: '717',
        },
        /*
         * @description Purchase failed because account is not eligible
         */
        PURCHASE_FAILED_BECAUSE_ACCOUNT_NOT_ELIGIBLE: {
            code: 'PURCHASE_FAILED_BECAUSE_ACCOUNT_NOT_ELIGIBLE',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Purchase failed because account is not eligible',
            numCode: '718',
        },
        /*
         * @description Instant Buy System Error
         */
        PURCHASE_FAILED_DUE_TO_INSTANT_BUY_SYSTEM_ERROR: {
            code: 'PURCHASE_FAILED_DUE_TO_INSTANT_BUY_SYSTEM_ERROR',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Instant Buy System Error',
            numCode: '719',
        },
        /*
         * @description Purchase failed due to missing content
         */
        PURCHASE_FAILED_DUE_TO_MISSING_ASSET: {
            code: 'PURCHASE_FAILED_DUE_TO_MISSING_ASSET',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Purchase failed due to missing content/asset',
            numCode: '720',
        },
        /*
         * @description Purchase failed due to conflict with this content
         */
        PURCHASE_FAILED_DUE_TO_CONTENT_CONFLICT: {
            code: 'PURCHASE_FAILED_DUE_TO_CONTENT_CONFLICT',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Purchase failed due to conflict with this content',
            numCode: '721',
        },
        /*
         * @description Purchase failed because not allowed
         */
        PURCHASE_FAILED_BECAUSE_NOT_ALLOWED: {
            code: 'PURCHASE_FAILED_BECAUSE_NOT_ALLOWED',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Purchase failed because not allowed',
            numCode: '722',
        },
        /*
         * @description Purchase failed because disabled by the server
         */
        PURCHASE_FAILED_BECAUSE_DISABLED_BY_SERVER: {
            code: 'PURCHASE_FAILED_BECAUSE_DISABLED_BY_SERVER',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Purchase failed because disabled by the server',
            numCode: '723',
        },
        /**
         * @description PURCHASE_FAILED_DUE_BACKEND_CONFIG
         */
        PURCHASE_FAILED_DUE_BACKEND_CONFIG: {
            code: 'PURCHASE_FAILED_DUE_BACKEND_CONFIG',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Purchase failed due to backend configuration',
            numCode: '724',
        },
        /**
         * @description PURCHASE_FAILED_DUE_DEVICE_NOT_FOUND
         */
        PURCHASE_FAILED_DUE_DEVICE_NOT_FOUND: {
            code: 'PURCHASE_FAILED_DUE_DEVICE_NOT_FOUND',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Purchase failed due to device not found',
            numCode: '725',
        },
        /**
         * @description PURCHASE_FAILED_DUE_SERVER_TIMEOUT
         */
        PURCHASE_FAILED_DUE_SERVER_TIMEOUT: {
            code: 'PURCHASE_FAILED_DUE_SERVER_TIMEOUT',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Purchase failed due to server timeout',
            numCode: '726',
        },
        /*
         * @description Purchase failed due to unexpected error
         */
        PURCHASE_FAILED_DUE_TO_UNEXPECTED_ERROR: {
            code: 'PURCHASE_FAILED_DUE_TO_UNEXPECTED_ERROR',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Purchase failed due to unexpected error',
            numCode: '727',
        },
        /*
         * @description Purchase failed due to unknown product
         */
        PURCHASE_FAILED_DUE_TO_UNKNOWN_PRODUCT: {
            code: 'PURCHASE_FAILED_DUE_TO_UNKNOWN_PRODUCT',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Purchase failed due to unknown product',
            numCode: '728',
        },

        /**
         *  COUPONS VALIDATION ERRORS
         */

        COUPON_VALIDATION_FAILED_COUPON_IS_NOT_VALID: {
            code: 'COUPON_VALIDATION_FAILED_COUPON_IS_NOT_VALID',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Coupon validation failed: coupon is not valid',
            numCode: '729',
        },

        COUPON_VALIDATION_FAILED_COUPON_IS_EMPTY: {
            code: 'COUPON_VALIDATION_FAILED_COUPON_IS_EMPTY',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Coupon validation failed: coupon is empty',
            numCode: '730',
        },

        COUPON_VALIDATION_FAILED_COUPON_ALREADY_USED: {
            code: 'COUPON_VALIDATION_FAILED_COUPON_ALREADY_USED',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Coupon validation failed: coupon has been already used',
            numCode: '731',
        },

        COUPON_VALIDATION_ERROR_INELIGIBLE_PRODUCT: {
            code: 'COUPON_VALIDATION_ERROR_INELIGIBLE_PRODUCT',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Coupon validation failed: specified product ineligible for coupon',
            numCode: '732',
        },

        /**
         **
         * PURCHASE ERROR FOR IAP (In App Purchase Google Apple play store)
         ** From 790 to 799
         */
        /**
         * @description PURCHASE_FAILED_DUE_TO_OUTDATED_IAP
         * This issue occurs when the user uses Google, Apple, Amazon App stores.
         * The transaction Token is no longer valid
         */
        PURCHASE_FAILED_DUE_TO_OUTDATED_IAP: {
            code: 'PURCHASE_FAILED_DUE_TO_OUTDATED_IAP',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Purchase Failed Outdated',
            numCode: '790',
        },
        /**
         * @description PURCHASE_FAILED_DUE_TO_MISSING_SKU
         * This issue occurs when the user uses Google, Apple, Amazon App stores.
         * The Google/Apple Appstore SKU (or Product ID) does not match with the Telco Product ID
         */
        PURCHASE_FAILED_DUE_TO_MISSING_SKU: {
            code: 'PURCHASE_FAILED_DUE_TO_MISSING_SKU',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Purchase Failed Missing SKU',
            numCode: '791',
        },
        /**
         * @description MISSING_IAP_RECEIPT
         */
        MISSING_IAP_RECEIPT: {
            code: 'MISSING_IAP_RECEIPT',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Missing IAP Receipt',
            numCode: '792',
        },
        /*
         * @description This issue occurs when IAP receipt does not contains an order id
         */
        PURCHASE_FAILED_DUE_TO_MISSING_ORDER_ID: {
            code: 'PURCHASE_FAILED_DUE_TO_MISSING_ORDER_ID',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Purchase failed due to missing order id in the iap receipt',
            numCode: '793',
        },
        /*
         * @description Default IAP (In app purchase error) when the validation of the receipt fails
         */
        PURCHASE_VALIDATION_IAP_RECEIPT_FAILED: {
            code: 'PURCHASE_VALIDATION_IAP_RECEIPT_FAILED',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Default IAP error when the validation of the appstore receipt fails',
            numCode: '794',
        },

        /**
         * @description PURCHASE_METHOD_ALREADY_OWNED
         */
        PURCHASE_METHOD_ALREADY_OWNED: {
            code: 'PURCHASE_METHOD_ALREADY_OWNED',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Referenced PaymentMethod is owned by different Account',
            numCode: '795',
        },

        /**
         * @description REDEEM_CODE_FAILED
         */
        REDEEM_CODE_FAILED: {
            code: 'REDEEM_CODE_FAILED',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Redeem code failed due to invalid code',
            numCode: '796',
        },

        /**
         * @description INSUFFICIENT_FUNDS
         */
        INSUFFICIENT_FUNDS: {
            code: 'INSUFFICIENT_FUNDS',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Purchase failed due to insufficient fund/credit',
            numCode: '797',
        },

        /**
         * @description PAYMENT_METHOD_ADDING_ERROR
         */
        PAYMENT_METHOD_ADDING_ERROR: {
            code: 'PAYMENT_METHOD_ADDING_ERROR',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Adding a new payment method failed',
            numCode: '798',
        },

        /**
         * @description PAYMENT_METHOD_DELETING_ERROR
         */
        PAYMENT_METHOD_DELETING_ERROR: {
            code: 'PAYMENT_METHOD_DELETING_ERROR',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Deleting exiting payment method failed',
            numCode: '799',
        },

        /**
         **
         * OTHER ERRORS - 000 (001 is Default in constructor)
         **
         */
        /**
         * @description FEATURE_NOT_AVAILABLE
         */
        FEATURE_NOT_AVAILABLE: {
            code: 'FEATURE_NOT_AVAILABLE',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Feature Not Available',
            numCode: '002',
        },
        /**
         * @description INVALID_API_ARGUMENT
         */
        INVALID_API_ARGUMENT: {
            code: 'INVALID_API_ARGUMENT',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Invalid API Argument',
            numCode: '003',
        },
        /**
         * @description INVALID_VOLT_API_ARGUMENT
         */
        INVALID_VOLT_API_ARGUMENT: {
            code: 'INVALID_VOLT_API_ARGUMENT',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Invalid Volt API Argument',
            numCode: '004',
        },
        /**
         * @description MISSING_API_METHOD_IMPLEMENTATION
         */
        MISSING_API_METHOD_IMPLEMENTATION: {
            code: 'MISSING_API_METHOD_IMPLEMENTATION',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Missing API Implementation',
            numCode: '005',
        },
        /**
         * @description OPERATION_ALREADY_DONE
         */
        OPERATION_ALREADY_DONE: {
            code: 'OPERATION_ALREADY_DONE',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Operation Already Done',
            numCode: '006',
        },
        /**
         * @description NOT_FOUND
         */
        NOT_FOUND: {
            code: 'NOT_FOUND',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Not Found',
            numCode: '007', // You can't locate a spy
            playerAutoRetry: true,
        },
        /**
         * @description SERVICE_NOT_ALLOWED
         */
        SERVICE_NOT_ALLOWED: {
            code: 'SERVICE_NOT_ALLOWED',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Service Not Allowed',
            numCode: '008',
            playerAutoRetry: false,
        },
        /**
         * @description UNHANDLED_API_RESPONSE_STRUCTURE
         */
        UNHANDLED_API_RESPONSE_STRUCTURE: {
            code: 'UNHANDLED_API_RESPONSE_STRUCTURE',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Unhandled API Structure',
            numCode: '009',
            playerAutoRetry: false,
        },
        /**
         * @description UNKNOWN_API_ERROR
         */
        UNKNOWN_API_ERROR: {
            code: 'UNKNOWN_API_ERROR',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Unknown API',
            numCode: '010',
            playerAutoRetry: true,
        },
        /**
         * @description UNKNOWN_ERROR
         */
        UNKNOWN_ERROR: {
            code: 'UNKNOWN_ERROR',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Unknown',
            numCode: '011',
            playerAutoRetry: true,
            disableFirebaseReporting: true,
        },
        /**
         * @description RUNTIME_ERROR
         */
        RUNTIME_ERROR: {
            code: 'RUNTIME_ERROR',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Runtime',
            numCode: '012',
            playerAutoRetry: true,
        },
        /**
         * @description Unknown error from the backend
         */
        UNKNOWN_BACKEND_ERROR: {
            code: 'UNKNOWN_BACKEND_ERROR',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Unknown error from the backend',
            numCode: '013',
            playerAutoRetry: true,
        },

        /**
         * @description SERVICE_NOT_AVAILABLE
         */
        SERVICE_NOT_AVAILABLE: {
            code: 'SERVICE_NOT_AVAILABLE',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Service not available',
            numCode: '014',
            playerAutoRetry: false,
        },

        // ----------------------------------
        /**
         * NAVIGATION ERRORS CATEGORIES
         * COMMONLY KNOWN AS VOLT STORE ERRORS
         * Type: is Navigation - 800
         */
        // ----------------------------------
        /**
         * Connectivity & User
         */
        /**
         * @description INTERNET_IS_UNREACHABLE
         */
        INTERNET_IS_UNREACHABLE: {
            code: 'INTERNET_IS_UNREACHABLE',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'No internet',
            numCode: '800',
        },
        /**
         * Recordings
         */
        /**
         * @description ADD_FAVORITE_REC_ERROR
         */
        ADD_FAVORITE_REC_ERROR: {
            code: 'ADD_FAVORITE_REC_ERROR',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Add Favorite Record',
            numCode: '801',
        },
        /**
         * @description DELETE_FAVORITE_REC_ERROR
         */
        DELETE_FAVORITE_REC_ERROR: {
            code: 'DELETE_FAVORITE_REC_ERROR',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Delete Favorite Record',
            numCode: '802',
        },
        /**
         * Programs
         */
        /**
         * @description ERROR_ADD_FAV_PGR
         */
        ERROR_ADD_FAV_PGR: {
            code: 'ERROR_ADD_FAV_PGR',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Add Favorite Program',
            numCode: '803',
        },
        /**
         * @description ERROR_REMOVE_FAV_PGR
         */
        ERROR_REMOVE_FAV_PGR: {
            code: 'ERROR_REMOVE_FAV_PGR',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Remove Favorite Program',
            numCode: '804',
        },
        /**
         * Reminder
         */
        /**
         * @description UNSET_REMINDER_ERROR
         */
        UNSET_REMINDER_ERROR: {
            code: 'UNSET_REMINDER_ERROR',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Unset Reminder',
            numCode: '805',
        },
        /**
         * Channels
         */
        /**
         * @description ADD_FAVORITE_CHANNEL_ERROR
         */
        ADD_FAVORITE_CHANNEL_ERROR: {
            code: 'ADD_FAVORITE_CHANNEL_ERROR',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Add Favorite Channel',
            numCode: '806',
        },
        /**
         * @description REMOVE_FAVORITE_CHANNEL_ERROR
         */
        REMOVE_FAVORITE_CHANNEL_ERROR: {
            code: 'REMOVE_FAVORITE_CHANNEL_ERROR',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Remove Favorite Channel',
            numCode: '807',
        },

        /**
         * @description RECORDING_NOT_FOUND
         */
        RECORDING_NOT_FOUND: {
            code: 'RECORDING_NOT_FOUND',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Recording not found',
            numCode: '808',
        },
        /**
         * @description RECORDING_FAILED
         */
        RECORDING_FAILED: {
            code: 'RECORDING_FAILED',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Recording failed',
            numCode: '809',
        },
        /**
         * @description EXCEEDED_QUOTA
         */
        EXCEEDED_QUOTA: {
            code: 'EXCEEDED_QUOTA',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Exceeded quota',
            numCode: '810',
        },
        /**
         * @description RECORDING_STATUS_NOT_VALID
         */
        RECORDING_STATUS_NOT_VALID: {
            code: 'RECORDING_STATUS_NOT_VALID',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Recording status not valid',
            numCode: '811',
        },
        /**
         * @description EXCEEDED_PROTECTION_QUOTA
         */
        EXCEEDED_PROTECTION_QUOTA: {
            code: 'EXCEEDED_PROTECTION_QUOTA',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Exceeded protection quota',
            numCode: '812',
        },
        /**
         * @description ALREADY_RECORDED_AS_SERIES_OR_SEASON
         */
        ALREADY_RECORDED_AS_SERIES_OR_SEASON: {
            code: 'ALREADY_RECORDED_AS_SERIES_OR_SEASON',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Already recorded as series or season',
            numCode: '813',
        },
        /**
         * @description SERIES_RECORDING_NOT_FOUND
         */
        SERIES_RECORDING_NOT_FOUND: {
            code: 'SERIES_RECORDING_NOT_FOUND',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Series recording not found',
            numCode: '814',
        },

        /**
         * @description PROXY_UNAVAILABLE
         */
        PROXY_UNAVAILABLE: {
            code: 'PROXY_UNAVAILABLE',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Proxy is unavailable',
            numCode: '815',
        },

        /**
         * @description BACKEND_AUTHENT_UNAVAILABLE
         */
        BACKEND_AUTHENT_UNAVAILABLE: {
            code: 'BACKEND_AUTHENT_UNAVAILABLE',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Backend authentication is unavailable',
            numCode: '816',
        },

        /**
         * @description CHANNELS_UNAVAILABLE
         */
        CHANNELS_UNAVAILABLE: {
            code: 'CHANNELS_UNAVAILABLE',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Channels are unavailable',
            numCode: '817',
        },

        /**
         * @description EPG_NOW_UNAVAILABLE
         */
        EPG_NOW_UNAVAILABLE: {
            code: 'EPG_NOW_UNAVAILABLE',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'EPG Now is unavailable',
            numCode: '818',
        },

        /**
         * @description RECORDING_NOT_ALLOWED
         */
        RECORDING_NOT_ALLOWED: {
            code: 'RECORDING_NOT_ALLOWED',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Recording not allowed, program, channel or user not allowed to record',
            numCode: '819',
        },

        /**
         * @description END_OF_LIVE_PLAYBACK
         */
        END_OF_LIVE_PLAYBACK: {
            code: 'END_OF_LIVE_PLAYBACK',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'end of live playback reached, should not happen on live stream',
            numCode: '820',
        },

        /**
         * @description RECORDING_NOT_ALLOWED_CHANNEL
         */
        RECORDING_NOT_ALLOWED_CHANNEL: {
            code: 'RECORDING_NOT_ALLOWED_CHANNEL',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Recording: channel not allowed  to record',
            numCode: '821',
        },

        /**
         * @description RECORDING_NOT_ALLOWED_PROGRAM
         */
        RECORDING_NOT_ALLOWED_PROGRAM: {
            code: 'RECORDING_NOT_ALLOWED_PROGRAM',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Recording: program not allowed to record',
            numCode: '822',
        },

        /**
         * @description RECORDING_NO_QUOTA_SUBSCRIPTION
         */
        RECORDING_NO_QUOTA_SUBSCRIPTION: {
            code: 'RECORDING_NO_QUOTA_SUBSCRIPTION',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Recording: User did not subscribe for quota',
            numCode: '823',
        },

        /**
         * @description RECORDING_FAILED_DUE_TO_CONFLICT
         */
        RECORDING_FAILED_DUE_TO_CONFLICT: {
            code: 'RECORDING_FAILED_DUE_TO_CONFLICT',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Recording: failure due to conflict',
            numCode: '824',
        },
        /**
         * @description RETENTION_PERIOD
         */
        RETENTION_PERIOD: {
            code: 'RETENTION_PERIOD',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Invalid retention period format',
            numCode: '825',
        },
        /**
         * @description AUTO_DELETE
         */
        AUTO_DELETE: {
            code: 'AUTO_DELETE',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Invalid configuration of auto deletion field',
            numCode: '826',
        },
        /**
         * @description INVALID_QUOTA
         */
        INVALID_QUOTA: {
            code: 'INVALID_QUOTA',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Invalid quota profile',
            numCode: '827',
        },

        /**
         * @description RECORDING_NO_RIGHT_FOR_ACCOUNT
         */
        RECORDING_NO_RIGHT_FOR_ACCOUNT: {
            code: 'RECORDING_NO_RIGHT_FOR_ACCOUNT',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'The account does not have right for NPVR',
            numCode: '828',
        },

        /**
         * @description RECORDING_REQUEST_ERROR
         */
        RECORDING_REQUEST_ERROR: {
            code: 'RECORDING_REQUEST_ERROR',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'The recording fails due to request error',
            numCode: '829',
        },
        /**
         * @description RECORDING_NOT_AVAILABLE_BECAUSE_CHANNEL_CEASED
         */
        RECORDING_NOT_AVAILABLE_BECAUSE_CHANNEL_CEASED: {
            code: 'RECORDING_NOT_AVAILABLE_BECAUSE_CHANNEL_CEASED',
            defaultContext: Constants.errorsContext.PLAYER_REACT,
            description: 'Reacording is not longer accessible because the channel has ceased',
            numCode: '830',
            playerAutoRetry: false,
        },

        /**
         * @description OPTA_UNEXPECTED
         */
        OPTA_UNEXPECTED: {
            code: 'OPTA_UNEXPECTED',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description:
                'An unexpected error has occurred. Verify that the URL, Outlet Authentication Key, query parameters and format are correct, and then try again.',
            numCode: '950',
        },

        /**
         * @description OPTA_NOT_FOUND
         */
        OPTA_NOT_FOUND: {
            code: 'OPTA_NOT_FOUND',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Page not found. You may have requested a page that does not exist.',
            numCode: '951',
        },

        /**
         * @description OPTA_INVALID_OUTLET
         */
        OPTA_INVALID_OUTLET: {
            code: 'OPTA_INVALID_OUTLET',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description:
                'Outlet does not exist. Incorrect or missing Outlet Authentication Key or mandatory character.',
            numCode: '952',
        },

        /**
         * @description OPTA_FEED_UNAVAILABLE
         */
        OPTA_FEED_UNAVAILABLE: {
            code: 'OPTA_FEED_UNAVAILABLE',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'The feed service, or search service, is not available at this time.',
            numCode: '953',
        },

        /**
         * @description OPTA_FEED_MISSING_PARAM
         */
        OPTA_FEED_MISSING_PARAM: {
            code: 'OPTA_FEED_MISSING_PARAM',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description:
                'Missing request parameter. A mandatory parameter has not been supplied. Ensure all mandatory parameters are supplied and correct.',
            numCode: '954',
        },

        /**
         * @description OPTA_FEED_UNKNOWN_PARAM
         */
        OPTA_FEED_UNKNOWN_PARAM: {
            code: 'OPTA_FEED_UNKNOWN_PARAM',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description:
                'Unknown request parameter. A parameter has been supplied that does not exist in the API. Check that the parameter and associated value exists for the feed, and that both are correct.',
            numCode: '955',
        },

        /**
         * @description OPTA_FEED_INVALID_PARAM
         */
        OPTA_FEED_INVALID_PARAM: {
            code: 'OPTA_FEED_INVALID_PARAM',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description:
                'Invalid request parameter value. You have passed invalid values to a valid query parameter, or multiple values to a parameter that only supports one value per request. Check the values and try again.',
            numCode: '956',
        },

        /**
         * @description OPTA_FEED_AMBIGUOUS_PARAM
         */
        OPTA_FEED_AMBIGUOUS_PARAM: {
            code: 'OPTA_FEED_AMBIGUOUS_PARAM',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description:
                'Ambiguous/Invalid request parameters. The request is invalid for one or more reasons.',
            numCode: '957',
        },

        /**
         * @description OPTA_FEED_INVALID_METHOD
         */
        OPTA_FEED_INVALID_METHOD: {
            code: 'OPTA_FEED_INVALID_METHOD',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description:
                'You are attempting to use POST with a GET-only end point (and vice-versa).',
            numCode: '958',
        },

        /**
         * @description OPTA_FEED_UNSUPPORTED_FORMAT
         */
        OPTA_FEED_UNSUPPORTED_FORMAT: {
            code: 'OPTA_FEED_UNSUPPORTED_FORMAT',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description:
                'Unsupported format: _fmt parameter / Accept header invalid default format.',
            numCode: '959',
        },

        /**
         * @description OPTA_FEED_UNSUPPORTED_MODE
         */
        OPTA_FEED_UNSUPPORTED_MODE: {
            code: 'OPTA_FEED_UNSUPPORTED_MODE',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description:
                'Unsupported mode: _rt parameter. Specify a valid value for the _rt parameter.',
            numCode: '960',
        },

        /**
         * @description OPTA_FEED_INVALID_DELTA
         */

        OPTA_FEED_INVALID_DELTA: {
            code: 'OPTA_FEED_INVALID_DELTA',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Invalid delta requested: _dlt parameter. Check the value specified.',
            numCode: '961',
        },

        /**
         * @description OPTA_FEED_INVALID_PAGINATION
         */
        OPTA_FEED_INVALID_PAGINATION: {
            code: 'OPTA_FEED_INVALID_PAGINATION',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description:
                'Invalid pagination requests – page size or page number request parameters/values are not valid.',
            numCode: '962',
        },

        /**
         * @description OPTA_FEED_INVALID_REQUEST
         */
        OPTA_FEED_INVALID_REQUEST: {
            code: 'OPTA_FEED_INVALID_REQUEST',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description:
                'Invalid request. Check if the parameter and value are valid and amend the request as necessary.',
            numCode: '963',
        },

        /**
         * @description OPTA_ACCESS_DENIED
         */
        OPTA_ACCESS_DENIED: {
            code: 'OPTA_ACCESS_DENIED',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description:
                'Feed Access Denied. Check that the feed is available within your subscription.',
            numCode: '964',
        },

        /**
         * @description OPTA_AUTH_EXPIRED
         */
        OPTA_AUTH_EXPIRED: {
            code: 'OPTA_AUTH_EXPIRED',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Oauth token expired',
            numCode: '965',
        },

        /**
         * @description OPTA_UNAUTHORIZED
         */
        OPTA_UNAUTHORIZED: {
            code: 'OPTA_UNAUTHORIZED',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'User not Authorised',
            numCode: '966',
        },

        /**
         * @description OPTA_AUTH_LIMIT
         */
        OPTA_AUTH_LIMIT: {
            code: 'OPTA_AUTH_LIMIT',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description:
                'Acceptable usage exceeded. Allow up to 30 minutes before sending further requests - if the error remains, contact your Account Manager.',
            numCode: '967',
        },

        /**
         * @description OPTA_FEED_NO_DATA
         */
        OPTA_FEED_NO_DATA: {
            code: 'OPTA_FEED_NO_DATA',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'No data found.',
            numCode: '968',
        },

        /**
         * @description OPTA_FEED_TIMEOUT
         */
        OPTA_FEED_TIMEOUT: {
            code: 'OPTA_FEED_TIMEOUT',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Data request timeout',
            numCode: '969',
        },
        /**
         * @description CONTACT_CUSTOMER_SERVICE
         */
        CONTACT_CUSTOMER_SERVICE: {
            code: 'CONTACT_CUSTOMER_SERVICE',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Issue with your account please contact our support in phone number *054',
            numCode: '970',
        },

        /**
         * @description VALIDATION_FAILED
         */
        VALIDATION_FAILED: {
            code: 'VALIDATION_FAILED',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'One of your details is wrong please try again',
            numCode: '971',
        },

        /**
         * @description REFUSE_OTP_AND_RECOMMEND_TO_LOGIN_WITH_USER_LOGIN_AND_PASSWORD
         */
        REFUSE_OTP_AND_RECOMMEND_TO_LOGIN_WITH_USER_LOGIN_AND_PASSWORD: {
            code: 'REFUSE_OTP_AND_RECOMMEND_TO_LOGIN_WITH_USER_LOGIN_AND_PASSWORD',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description:
                'Issue found with your account please contact our support in phone number *054',
            numCode: '972',
        },
        SUBSCRIPTION_REACTIVATION_FAILED: {
            code: 'SUBSCRIPTION_REACTIVATION_FAILED',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Subscription Reactivation Failed',
            numCode: '973',
        },
        SUBSCRIPTION_PAUSE_FAILED: {
            code: 'SUBSCRIPTION_PAUSE_FAILED',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Subscription Pause Failed',
            numCode: '974',
        },
        SUBSCRIPTION_RESUME_FAILED: {
            code: 'SUBSCRIPTION_RESUME_FAILED',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Subscription Resume Failed',
            numCode: '975',
        },
        UPDATE_AUTH_IDENTIFIER_START_FAILED: {
            code: 'UPDATE_AUTH_IDENTIFIER_START_FAILED',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Update identifier process start failed',
            numCode: '976',
        },
        UPDATE_AUTH_IDENTIFIER_STEP_FAILED: {
            code: 'UPDATE_AUTH_IDENTIFIER_STEP_FAILED',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Update identifier process step failed',
            numCode: '977',
        },
        UPDATE_AUTH_IDENTIFIER_OTP_VALIDATION_FAILED: {
            code: 'UPDATE_AUTH_IDENTIFIER_OTP_VALIDATION_FAILED',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Update identifier otp validation failed',
            numCode: '978',
        },
        UPDATE_AUTH_IDENTIFIER_RESEND_OTP_FAILED: {
            code: 'UPDATE_AUTH_IDENTIFIER_RESEND_OTP_FAILED',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Update identifier resend otp failed',
            numCode: '979',
        },
        UPDATE_GET_USER_INFO_FAILED: {
            code: 'UPDATE_GET_USER_INFO_FAILED',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Update get user info failed',
            numCode: '980',
        },
        UPDATE_AUTH_ADDRESS_UPDATE_FAILED: {
            code: 'UPDATE_AUTH_ADDRESS_UPDATE_FAILED',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Account address update failed',
            numCode: '981',
        },
        SUBSCRIPTION_UPDATE_FAILED: {
            code: 'SUBSCRIPTION_UPDATE_FAILED',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Subscription Update Failed',
            numCode: '982',
        },
        SUBSCRIPTION_CHECKOUT_FAILED: {
            code: 'SUBSCRIPTION_CHECKOUT_FAILED',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Subscription Checkout Failed',
            numCode: '983',
        },
        ADDRESS_VERIFICATION_FAILED: {
            code: 'ADDRESS_VERIFICATION_FAILED',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Address verfication failed',
            numCode: '984',
        },

        /**
         * @description GET_SELECTED_PAYMENT_METHOD_ERROR
         */
        GET_SELECTED_PAYMENT_METHOD_ERROR: {
            code: 'GET_SELECTED_PAYMENT_METHOD_ERROR',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Getting selected payment method failed',
            numCode: '985',
        },
        SEARCH_USER_ERROR: {
            code: 'SEARCH_USER_ERROR',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Search user failed',
            numCode: '986',
        },
        GET_ADMIN_PROFILE_ERROR: {
            code: 'GET_ADMIN_PROFILE_ERROR',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Failed to get admin profile',
            numCode: '987',
        },
        ADMIN_LOGOUT_ERROR: {
            code: 'ADMIN_LOGOUT_ERROR',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Failed to logout admin',
            numCode: '988',
        },
        IMPERSONATE_USER_REQUEST_ERROR: {
            code: 'IMPERSONATE_USER_REQUEST_ERROR',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Failed to request user for impersonation',
            numCode: '989',
        },
        IMPERSONATE_USER_DETAILS_ERROR: {
            code: 'IMPERSONATE_USER_DETAILS_ERROR',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Failed to get user details for impersonation',
            numCode: '990',
        },
        GET_IMPERSONATE_TOKEN_ERROR: {
            code: 'GET_IMPERSONATE_TOKEN_ERROR',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Failed to get impersonation token for user',
            numCode: '991',
        },
        /**
         * @description ELIGIBILITY_FAILED
         */
        ELIGIBILITY_FAILED: {
            code: 'ELIGIBILITY_FAILED',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Campaign not eligible',
            numCode: '992',
        },
        ONBOARD_AND_AUTHENTICATE_START_FAILED: {
            code: 'ONBOARD_AND_AUTHENTICATE_START_FAILED',
            defaultContext: Constants.errorsContext.NAVIGATION,
            description: 'Onboard and Authenticate start failed',
            numCode: '993',
        },
    }
}
